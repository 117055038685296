import instance from './API'

export function postJdStatusUpdate(token, status, campaign_id) {
  const formData = new FormData()
  formData.append('campaign_id', campaign_id)
  formData.append('status', status)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('campaign/status/', formData, config)
}

export function postJdReassignToUpdate(
  token,
  reassign_to,
  campaign_id,
  old_user_id,
  is_unassigned,
) {
  const formData = new FormData()
  formData.append('campaign_id', campaign_id)
  formData.append('reassign_to_id', reassign_to)
  formData.append('old_user_id', old_user_id)
  formData.append('is_unassigned', is_unassigned)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('campaign/reassign/', formData, config)
}

export function getLiveScarping(token) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      offset: 0,
      limit: 100,
    },
  }
  // Need to be Removed
  return instance.get('jds/scraping/live/', config)
}

export function updateLiveScraping(token, campaign_id) {
  const formData = new FormData()
  formData.append('campaign_id', campaign_id)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }
  // Need to be Removed
  return instance.post('jds/scraping/live/', formData, config)
}

export function getJDExcelData(campaign_id) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      campaign_id,
    },
  }
  // Need to be Removed
  return instance.get('', config)
}

export function getExcelCache(campaign_id) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      campaign_id: campaign_id,
    },
  }

  return instance.get('excel/data/', config)
}

export function postExcelCache(campaign_id, data) {
  const formData = new FormData()
  formData.append('campaign_id', campaign_id)
  formData.append('data', data)

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  console.log('syncData api call happened')

  return instance.post('excel/data/', formData, config)
}

export function postExcelUpdate(data) {
  const formData = new FormData()
  formData.append('data', data)

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  console.log('syncData api call happened')

  return instance.post('excel/update/', formData, config)
}
