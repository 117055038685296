import moment from 'moment-timezone'
import * as moment2 from 'moment'

export function getReadableDate(date) {
  if (date?.length === '') return date
  // let pd = moment(date)
  // let zone = Intl.DateTimeFormat().resolvedOptions().timeZone
  // let finalDate = mom.tz(zone)
  // console.log("Timezone with nozone", mom.format("z"))
  return moment(date).format('dddd DD, MMMM YY')
}

export function getReadableDateTime(date) {
  if (date?.length === '') return date
  // let mom = moment(date)
  // let zone = Intl.DateTimeFormat().resolvedOptions().timeZone
  // let finalDate = mom.tz(zone)
  // console.log("FinalDate", finalDate.format("z"))
  // return finalDate.format("hh:mm, dddd DD, MMMM YY")
  return moment(date).format('dddd MMMM, DD hh:mm a')
}

export function getReadableDateTimeWithYear(date) {
  if (date?.length === '') return date
  // let mom = moment(date)
  // let zone = Intl.DateTimeFormat().resolvedOptions().timeZone
  // let finalDate = mom.tz(zone)
  // console.log("FinalDate", finalDate.format("z"))
  // return finalDate.format("hh:mm, dddd DD, MMMM YY")
  return moment(date).format('dddd, MMMM DD, YYYY hh:mm a')
}

export function getReadableTimeDiff(date) {
  if (date?.length === 0) return 0
  console.log(date)
  const m = moment2(date)
  const zone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const final = moment(m).tz(zone)
  console.log('Timezone', final.format('hh:mm a z'))
  return final.fromNow(false)
}

export function getReadableDateTimeYear(date) {
  if (date?.length === '') return date
  // let mom = moment(date)
  // let zone = Intl.DateTimeFormat().resolvedOptions().timeZone
  // let finalDate = mom.tz(zone)
  // console.log("FinalDate", finalDate.format("z"))
  // return finalDate.format("hh:mm, dddd DD, MMMM YY")
  return moment(date).format('DD/MM/YY, hh:mm a')
}

export function getDefaultDate(date) {
  if (date?.length === '') return date
  // let mom = moment(date)
  // let zone = Intl.DateTimeFormat().resolvedOptions().timeZone
  // let finalDate = mom.tz(zone)
  // console.log("FinalDate", finalDate.format("z"))
  // return finalDate.format("hh:mm, dddd DD, MMMM YY")
  return moment(date).format('YYYY-MM-DD')
}
