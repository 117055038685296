import instance from './API'

export function sendWhatsapp(
  token,
  lead_ids,
  campaign_id,
  message,
  templateValue,
  statusID,
  message_id = 0,
) {
  console.log(
    'sendWhatsapp',
    lead_ids,
    campaign_id,
    message,
    statusID,
    templateValue,
  )
  const formData = new FormData()
  formData.append('campaign_id', campaign_id)
  formData.append('lead_ids', lead_ids)
  formData.append('message', message)
  formData.append('status_id', statusID)
  formData.append('template_id', templateValue)
  formData.append('chat_message_id', message_id)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('send/whatsapp/', formData, config)
}

export function sendContacts(token, contact_data) {
  const formData = new FormData()
  formData.append('contact_data', JSON.stringify(contact_data))

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('phonebook/sendcontacts/', formData, config)
}

export function contactOut(linkedin, lead_id, campaign_id) {
  const formData = new FormData()
  formData.append('linkedin', linkedin)
  formData.append('lead_id', lead_id)
  formData.append('campaign_id', campaign_id)

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.post('campaigns/tasks/contact/', formData, config)
}
