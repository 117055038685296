import { Button, Card, CardBody } from 'reactstrap'
import { Tooltip } from '@mui/material'
import LinkIcon from '@mui/icons-material/Link'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckIcon from '@mui/icons-material/Check'
import EditNoteIcon from '@mui/icons-material/EditNote'
import DeleteIcon from '@mui/icons-material/Delete'
import moment from 'moment-timezone'
import React from 'react'

export function MeetingCard({
  data,
  handleEventClick,
  markAsDone,
  editHistory,
  handleDelete,
}) {
  return (
    <Card className="bg-light-primary rounded mb-1">
      <CardBody className="p-1">
        <div className="text-end  d-flex justify-content-end align-items-center">
          {data.event_link ? (
            <Button
              variant="text"
              color="primary"
              className="p-0 d-flex justify-content-center align-items-center"
              disableElevation
              onClick={(e) => handleEventClick(e, data.event_link)}
            >
              <Tooltip title={'Meeting Link'} arrow placement="top">
                <LinkIcon className="font-medium-1" />
              </Tooltip>
            </Button>
          ) : (
            ''
          )}

          <Button
            variant="text"
            color="primary"
            className="p-0 d-flex justify-content-center align-items-center"
            disableElevation
            disabled={data?.is_completed === 1}
            onClick={() => markAsDone(data)}
          >
            <Tooltip title={'Mark As Completed'} arrow placement="top">
              {data?.is_completed === 0 ? (
                <CheckBoxOutlineBlankIcon className="font-medium-1" />
              ) : (
                <CheckIcon className="font-medium-1" />
              )}
            </Tooltip>
          </Button>

          <Button
            variant="text"
            color="primary"
            className="p-0 d-flex justify-content-center align-items-center mx-1"
            disableElevation
            onClick={() => editHistory(data)}
          >
            <Tooltip title={'Edit'} arrow placement="top">
              <EditNoteIcon className="font-medium-1" />
            </Tooltip>
          </Button>
          <Button
            variant="text"
            color="primary"
            className="p-0 d-flex justify-content-center align-items-center"
            disableElevation
            onClick={() => handleDelete(data)}
          >
            <Tooltip title={'Delete'} arrow placement="top">
              <DeleteIcon className="font-medium-1" />
            </Tooltip>
          </Button>
        </div>
        <div className="row ">
          <div className="col-3">
            <span className="mb-2">Title</span>
          </div>
          <div className="col-1">-</div>
          <div className="col-8">
            <span className="">{data?.title}</span>
          </div>
        </div>

        <div className="row ">
          <div className="col-3">
            <span className="mb-2">Campaign name</span>
          </div>
          <div className="col-1">-</div>
          <div className="col-8">
            <span className="">{data?.campaign_name}</span>
          </div>
        </div>
        <div className="row ">
          <div className="col-3">
            <span className="mb-2">Client Name</span>
          </div>
          <div className="col-1">-</div>
          <div className="col-8">
            <span className="">{data?.client_name}</span>
          </div>
        </div>

        <div className="row ">
          <div className="col-3">
            <span className="mb-2">Candidate name</span>
          </div>
          <div className="col-1">-</div>
          <div className="col-8">
            <span className="">{data?.name}</span>
          </div>
        </div>

        <div className="row ">
          <div className="col-3">
            <span className="mb-2">Status</span>
          </div>
          <div className="col-1">-</div>
          <div className="col-8">
            <span className="">{data?.status_value}</span>
          </div>
        </div>
        <div className="row ">
          <div className="col-3">
            <span className="mb-2">Feedback</span>
          </div>
          <div className="col-1">-</div>
          <div className="col-8">
            <span className="">{data?.feedback}</span>
          </div>
        </div>

        <div className="row ">
          <div className="col-12 text-end">
            <i className="font-small-2">
              {moment(data?.scheduled_date).format(
                'dddd, MMMM Do YYYY, h:mm a',
              )}
            </i>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}
