import instance from './API'

export function getCVS(
  token,
  campaign_id,
  candidate_status,
  start,
  end,
  start_id_col,
  start_id_val,
  filters = {},
  filter_mode = 'original',
  search_param = '',
) {
  if (filter_mode === 'original') {
    filters = {}
  }
  console.log(
    'JSON.stringify(filters) ',
    JSON.stringify(filters),
    filters,
    filter_mode,
  )
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      campaign_id,
      filters: JSON.stringify(filters),
      start,
      end,
      start_id_col,
      start_id_val,
      candidate_status,
      search_param,
    },
  }

  return instance.get('leads/', config)
}

export function getNewProfilesCount(token, campaign_id) {
  const formData = new FormData()
  formData.append('campaign_id', campaign_id)
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('lead/newprofiles/', formData, config)
}

export function getSearchProfiles(
  token,
  query,
  campaign_id,
  filters,
  filter_mode = 'original',
) {
  if (filter_mode === 'original') {
    filters = {}
  }
  const formData = new FormData()
  formData.append('campaign_id', campaign_id)
  formData.append('query', query)
  formData.append('filters', JSON.stringify(filters))

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }
  // need to be removed
  return instance.post('lead/search/', formData, config)
}

export function getNaukriCV(token, lead_id) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      lead_id,
    },
  }

  return instance.get('lead/naukri/', config)
}

export function getRawCV(token, lead_id, campaign_id) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      lead_id,
      campaign_id,
    },
  }

  return instance.get('cv/raw/', config)
}

export function updatePhoneEmail(token, lead_id, type, value) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const formData = new FormData()
  formData.append('lead_id', lead_id)

  if (type === 'Email') {
    formData.append('email', value)
  } else {
    formData.append('phone', value)
  }

  return instance.post('leads/', formData, config)
}

export function postCvStatusUpdate(token, lead_id, status, campaign_id) {
  const formData = new FormData()
  formData.append('campaign_id', campaign_id)
  formData.append('status', status)
  formData.append('lead_id', lead_id)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('lead/status/', formData, config)
}

export function postCvContact(token, lead_id, campaign_id) {
  const formData = new FormData()
  formData.append('campaign_id', campaign_id)
  formData.append('lead_id', lead_id)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('lead/contact/', formData, config)
}

// export function getCVMatching(token, lead_id, campaign_id) {
//
//     const config = {
//         headers: {
//             'Authorization': "Token " + token,
//             'Content-Type': 'application/json',
//         },
//         params: {
//             campaign_id: campaign_id,
//             lead_id: lead_id
//         }
//     }
//
//     return instance.get("cv-match/", config)
// }

export function postNaukriOTP(token, campaign_id, otp) {
  const formData = new FormData()
  formData.append('otp', otp)
  formData.append('campaign_ids', campaign_id)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('otp/naukri/', formData, config)
}

export function postLinkedinOTP(token, campaign_id, otp) {
  const formData = new FormData()
  formData.append('otp', otp)
  formData.append('campaign_ids', campaign_id)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('otp/linkedin/', formData, config)
}

export function postCvScraping(token, userID, campaign_id) {
  const formData = new FormData()
  formData.append('uuid', userID)
  formData.append('campaign_ids', campaign_id)
  //Need to be Removed
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }
  //Need to be Removed
  return instance.post('', formData, config)
}

export function sendEmail(
  campaign_id,
  lead_ids,
  subject,
  template_id,
  tags,
  statusID = -1,
  message = '',
  files = [],
) {
  const formData = new FormData()

  formData.append('campaign_id', campaign_id)
  formData.append('lead_ids', lead_ids)
  formData.append('subject', subject)
  formData.append('template_id', template_id)
  formData.append('tags', tags)
  formData.append('status_id', statusID)
  formData.append('message', message)
  // formData.append('files', files)
  files.forEach((file, index) => {
    formData.append(`files`, file)
  })

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }

  return instance.post('send/email/', formData, config)
}

export function sendSMS(campaign_id, lead_ids, jdRole, statusID = -1) {
  const formData = new FormData()
  formData.append('campaign_id', campaign_id)
  formData.append('lead_ids', lead_ids)
  formData.append('jdRole', jdRole)
  formData.append('status_id', statusID)

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.post('send/sms/', formData, config)
}

export function postCvClickView(token, data) {
  const formData = new FormData()
  formData.append('data', data)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('lead/click/', formData, config)
}

export function uploadNaukriCV(token, lead_id, campaign_id, file) {
  const formData = new FormData()
  formData.append('campaign_id', campaign_id)
  formData.append('lead_id', lead_id)
  formData.append('file', file)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }
  // Need to be Removed
  return instance.post('', formData, config)
}

export function export_profileApi(token, campaign_id, uuid) {
  const formData = new FormData()
  formData.append('campaign_id', campaign_id)
  formData.append('uuid', uuid)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('lead/export/', formData, config)
}

export function updateRankingOld(token, campaign_id, des, skills, exp) {
  const formData = new FormData()
  formData.append('campaign_id', campaign_id)
  formData.append('skill_scaling_factor', skills)
  formData.append('designation_scaling_factor', des)
  formData.append('exp_scaling_factor', exp)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }
  //  Need to be Removed
  return instance.post('cv/ranking/update/', formData, config)
}

export function updateRanking(token, campaign_id, des, skills, exp) {
  const formData = new FormData()
  formData.append('campaign_id', campaign_id)
  formData.append('skill_scaling_factor', skills)
  formData.append('designation_scaling_factor', des)
  formData.append('exp_scaling_factor', exp)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('cv/ranking/update/', formData, config)
}

export function runRanking(token, campaign_id) {
  const formData = new FormData()
  formData.append('campaign_id', campaign_id)

  const config = {
    headers: {
      //'Authorization': "Token " + token,
      'Content-Type': 'application/json',
    },
  }
  // Need to be Removed -- Doubt
  return instance.post('cv/ranking/', formData, config)
}
