import instance from './API'

export function getComments(token, lead_id) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      lead_id,
    },
  }

  return instance.get('lead/profile/comments/', config)
}
// Need to change the Camel Case to Snake Case
// comments
// lead_id
// campaign_id

export function postComment(token, lead_id, comments, campaign_id) {
  const formData = new FormData()
  formData.append('comments', comments)
  formData.append('lead_id', lead_id)
  formData.append('campaign_id', campaign_id)
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      lead_id,
      comments,
      campaign_id,
    },
  }

  return instance.post('lead/profile/comments/', formData, config)
}

// export function deleteComment(token, id) {
//   console.log('deleteComment', id)
//   const formData = new FormData()
//   formData.append('id', id)
//   const config = {
//     headers: {
//       Authorization: `Token ${token}`,
//       'Content-Type': 'application/json',
//     },
//   }

//   return instance.delete('cv/profile/comments/', config)
// }

export function deleteComment(token, id) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    data: {
      id,
    },
  }

  return instance.delete('lead/profile/comments/', config)
}
