// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as api from '@src/api/ChatApi'
import * as jdsApi from '@src/api/JdHistoryApi'

// ** Axios Imports
import axios from 'axios'

let offset = 0
let limit = 100
let channel = 'INMAIL'

export const getChats = createAsyncThunk(
  'inmail/getChats',
  async (campaign_id) => {
    // const response = await axios.get('/apps/chat/users/profile-user')
    const response = await api.getChatAll(campaign_id, offset, limit, channel)
    return response.data
  },
)

export const getChatsV2 = createAsyncThunk(
  'inmail/getChatsV2',
  async (
    {
      detail_urls,
      from_senders,
      is_reads,
      originTokens,
      sdkEntityUrns,
      texts,
      to_senders,
      sdk_urls_orig,
      mailbox_urns,
      lastActivityAts,
      from_linkedin_ids,
    },
    { rejectWithValue },
  ) => {
    // const response = await axios.get('/apps/chat/users/profile-user')

    return {
      detail_urls,
      from_senders,
      is_reads,
      originTokens,
      sdkEntityUrns,
      texts,
      to_senders,
      sdk_urls_orig,
      mailbox_urns,
      lastActivityAts,
      from_linkedin_ids,
    }
  },
)

export const getConnectionChatsV2 = createAsyncThunk(
  'inmail/getConnectionChatsV2',
  async (
    {
      connection_detail_urls,
      connection_from_senders,
      connection_is_reads,
      connection_originTokens,
      connection_sdkEntityUrns,
      connection_texts,
      connection_to_senders,
      connection_sdk_urls_orig,
      connection_mailbox_urns,
      connection_lastActivityAts,
      connection_from_linkedin_ids,
    },
    { rejectWithValue },
  ) => {
    // const response = await axios.get('/apps/chat/users/profile-user')

    return {
      connection_detail_urls,
      connection_from_senders,
      connection_is_reads,
      connection_originTokens,
      connection_sdkEntityUrns,
      connection_texts,
      connection_to_senders,
      connection_sdk_urls_orig,
      connection_mailbox_urns,
      connection_lastActivityAts,
      connection_from_linkedin_ids,
    }
  },
)

export const getChatDetailsV2 = createAsyncThunk(
  'inmail/getChatDetailsV2',
  async ({ detail_url, senders, times, texts }, { rejectWithValue }) => {
    // const response = await axios.get('/apps/chat/users/profile-user')
    let chat_details = {
      senders: senders,
      times: times,
      texts: texts,
    }
    return { chat_details: chat_details, detail_url: detail_url }
  },
)

export const getUserProfile = createAsyncThunk('inmail/getTasks', async () => {
  const response = await axios.get('/apps/chat/users/profile-user')
  return response.data
})

export const getChatContacts = createAsyncThunk(
  'inmail/getChatContacts',
  async ({ campaign_id, start, end }, { rejectWithValue }) => {
    const response = await jdsApi.getJdChoosenTasks(
      '',
      campaign_id,
      start,
      end,
      'y',
      ' SUBSTR(phone_number, 1, 1) desc , rank asc, email desc ',
    )
    console.log('SHUDIPTO TASK', response)
    return { Data: response.data.Data, start: start }
  },
)

export const updateJDname = createAsyncThunk(
  'inmail/updateJDname',
  async ({ campaign_name }, { rejectWithValue }) => {
    console.log('updateJDname: ', campaign_name)
    try {
      return { Data: campaign_name }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const selectChat = createAsyncThunk(
  'inmail/selectChat',
  async ({ lead_id, offset, limit, setIsChatLoaded }, { rejectWithValue }) => {
    // const response = await axios.get('/apps/chat/get-chat', { id })
    // await dispatch(getChatContacts())
    try {
      const response = await api.getChatDetails(lead_id, offset, limit)
      setIsChatLoaded(true)
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const selectChatV2 = createAsyncThunk(
  'inmail/selectChatV2',
  async ({ lead_id, offset, limit, setIsChatLoaded }, { rejectWithValue }) => {
    // const response = await axios.get('/apps/chat/get-chat', { id })
    // await dispatch(getChatContacts())
    try {
      const response = await api.getChatDetails(lead_id, offset, limit)
      setIsChatLoaded(true)
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const sendMsg = createAsyncThunk(
  'inmail/sendMsg',
  async (obj, { dispatch }) => {
    const response = await axios.post('/apps/chat/send-msg', { obj })
    await dispatch(selectChat(obj.contact.id))
    return response.data
  },
)

export const clearAllInmails = createAsyncThunk(
  'inmail/clearChats',
  async ({}, { rejectWithValue }) => {
    return { Data: [], folder_name: '' }
  },
)

export const currentChatInfo = createAsyncThunk(
  'inmail/currentChatInfo',
  async ({ item }, { rejectWithValue }) => {
    console.log('currentChatInfo ')
    try {
      return { Data: item }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const tempIncrement = createAsyncThunk(
  'inmail/tempIncrement',
  async (
    { detail_urls, to_senders, from_senders, is_reads, texts, lastActivityAts },
    { rejectWithValue },
  ) => {
    console.log('clearAllEmails ')
    try {
      return {
        detail_urls: detail_urls.concat(detail_urls),
        to_senders: to_senders.concat(to_senders),
        from_senders: from_senders.concat(from_senders),
        is_reads: is_reads.concat(is_reads),
        texts: texts.concat(texts),
        lastActivityAts: lastActivityAts.concat(lastActivityAts),
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const appChatSlice = createSlice({
  name: 'inmail',
  initialState: {
    detail_urls: [],
    from_senders: [],
    is_reads: [],
    originTokens: [],
    sdkEntityUrns: [],
    texts: [],
    to_senders: [],
    sdk_urls_orig: [],
    mailbox_urns: [],
    lastActivityAts: [],
    from_linkedin_ids: [],

    chat_details: {},
    detail_url: '',

    connection_detail_urls: [],
    connection_from_senders: [],
    connection_is_reads: [],
    connection_originTokens: [],
    connection_sdkEntityUrns: [],
    connection_texts: [],
    connection_to_senders: [],
    connection_sdk_urls_orig: [],
    connection_mailbox_urns: [],
    connection_lastActivityAts: [],
    connection_from_linkedin_ids: [],

    chats: [],
    contacts: [],
    userProfile: {},
    selectedUserChat: [],
    selectedUserInfo: {},
    campaign_name: 'all',
    total_records: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(tempIncrement.fulfilled, (state, action) => {
        state.detail_urls = action.payload.detail_urls
        state.from_senders = action.payload.from_senders
        state.is_reads = action.payload.is_reads
        state.texts = action.payload.texts
        state.to_senders = action.payload.to_senders
        state.lastActivityAts = action.payload.lastActivityAts
      })
      .addCase(getConnectionChatsV2.fulfilled, (state, action) => {
        state.connection_detail_urls = action.payload.connection_detail_urls
        state.connection_from_senders = action.payload.connection_from_senders
        state.connection_is_reads = action.payload.connection_is_reads
        state.connection_originTokens = action.payload.connection_originTokens
        state.connection_sdkEntityUrns = action.payload.connection_sdkEntityUrns
        state.connection_texts = action.payload.connection_texts
        state.connection_to_senders = action.payload.connection_to_senders
        state.connection_sdk_urls_orig = action.payload.connection_sdk_urls_orig
        state.connection_mailbox_urns = action.payload.connection_mailbox_urns
        state.connection_lastActivityAts =
          action.payload.connection_lastActivityAts
        state.connection_from_linkedin_ids =
          action.payload.connection_from_linkedin_ids
      })
      .addCase(getChatsV2.fulfilled, (state, action) => {
        state.detail_urls = action.payload.detail_urls
        state.from_senders = action.payload.from_senders
        state.is_reads = action.payload.is_reads
        state.originTokens = action.payload.originTokens
        state.sdkEntityUrns = action.payload.sdkEntityUrns
        state.texts = action.payload.texts
        state.to_senders = action.payload.to_senders
        state.sdk_urls_orig = action.payload.sdk_urls_orig
        state.mailbox_urns = action.payload.mailbox_urns
        state.lastActivityAts = action.payload.lastActivityAts
        state.from_linkedin_ids = action.payload.from_linkedin_ids
      })
      .addCase(getChatDetailsV2.fulfilled, (state, action) => {
        if (state.chat_details === undefined || state.chat_details === null) {
          return
        }
        //state.chat_details = action.chat_details
        let chat_details = {}
        chat_details[action.payload.detail_url] = action.payload.chat_details

        state.chat_details = chat_details
        state.detail_url = action.payload.detail_url
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.userProfile = action.payload
      })
      .addCase(getChats.fulfilled, (state, action) => {
        let lead_ids_covered = []
        let res = action.payload.Data

        res = res.filter((k) => {
          if (lead_ids_covered.includes(k.lead_id)) {
            return false
          } else {
            lead_ids_covered.push(k.lead_id)
            return true
          }
        })

        console.log('action.payload.Data: ', action.payload.Data)
        state.chats = res
      })
      .addCase(getChatContacts.fulfilled, (state, action) => {
        console.log('appChat extraReducers: ', action)
        if (action.payload.start === 0) {
          state.contacts = action.payload.Data.data
        } else {
          let earlier_records = [...state.contacts]
          state.contacts = earlier_records.concat(action.payload.Data.data)
        }
        state.total_records = action.payload.Data?.total?.[0][0]
      })
      .addCase(selectChat.fulfilled, (state, action) => {
        console.log('appChat extraReducers: ', action)
        if (action?.payload?.Response == 'Ok') {
          state.selectedUserChat = action?.payload?.Data ?? []
        } else {
          state.selectedUserChat = []
        }
      })
      .addCase(selectChat.rejected, (state, action) => {
        console.log('appChat extraReducers: ', action)
        state.selectedUserChat = []
      })
      .addCase(currentChatInfo.fulfilled, (state, action) => {
        console.log('appChat extraReducers: ', action)
        state.selectedUserInfo = action?.payload?.Data ?? {}
      })
      .addCase(currentChatInfo.rejected, (state, action) => {
        console.log('appChat extraReducers: ', action)
        state.selectedUserInfo = {}
      })
      .addCase(updateJDname.fulfilled, (state, action) => {
        console.log('appChat extraReducers: ', action)
        state.campaign_name = action?.payload?.Data ?? {}
        state.total_records = 0
      })
      .addCase(updateJDname.rejected, (state, action) => {
        console.log('appChat extraReducers: ', action)
        state.campaign_name = ''
        state.total_records = 0
      })
      .addCase(clearAllInmails.fulfilled, (state, action) => {
        console.log('appChat extraReducers: ', action)

        state.chats = []
        state.contacts = []
        state.userProfile = {}
        state.selectedUserChat = []
        state.selectedUserInfo = {}
        state.campaign_name = 'all'
        state.total_records = 0
      })
      .addCase(clearAllInmails.rejected, (state, action) => {
        console.log('appChat extraReducers: ', action)

        state.chats = []
        state.contacts = []
        state.userProfile = {}

        state.selectedUserChat = []
        state.selectedUserInfo = {}
        state.campaign_name = 'all'
        state.total_records = 0
      })
  },
})

export default appChatSlice.reducer
