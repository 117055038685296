import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { makeStyles } from '@mui/styles'
import { Alert } from '@mui/material'
import SendSMSDialog from './SendSMSDailoge'
import SendWhatsAppDialog from './sendWhatsappDialog'
import { SendEmailDialog } from './SendEmailDialog'
import ScheduleMeeting from './ScheduleMeeting'
import * as whatappAPI from '../../../../api/ContactApi'
import Slide from '@mui/material/Slide'

export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    //marginLeft: theme.spacing(2),
    flex: 1,
  },
  myCard: {
    cursor: 'pointer',
  },
}))

//AIzaSyDKDp3xpG5FRmh8j0FmtR4ce6MoWXGRhG8

const InteractionTypeCard = (props, theme) => {
  const classes = useStyles()

  console.log('InteractionTypeCard props', props)
  let token = props.props.token
  let name = props.props.name
  let campaign_id = props.props.campaign_id
  let lead_id = props.props.id
  let jdRole = props.props.jdRole

  const [showSMS, setSMSModel] = useState(false)
  const [showEmail, setEmailModel] = useState(false)
  const [showMeeting, setMeetingModel] = useState(false)
  const [open, setOpen] = React.useState(false)

  //****************************************************************************************************
  // ******************** SMS **************************************

  const handleSMS = (status) => setSMSModel(status)
  const handleEmail = (status) => setEmailModel(status)
  const handleMeeting = (status) => setMeetingModel(status)

  const [smsRole, setSmsRole] = React.useState(jdRole)

  //****************************************************************************************************

  //****************************************************************************************************
  // ******************** Meetings **************************************
  let attendees = [
    {
      email: props.props.organizerEmail ?? '',
      displayName: props.props.organizerName ?? '',
    },
    {
      email: props.props.email?.split(',')?.[0] ?? '',
      displayName: props.props.name ?? '',
    },
  ]
  //****************************************************************************************************

  //****************************************************************************************************
  // ********************* Whatsapp
  const [showWhatsapp, setWhatsapp] = useState(false)
  const handleWhatsapp = (status) => setWhatsapp(status)

  const handleWhatappSend = (e) => {
    whatappAPI
      .sendWhatsapp(token, lead_id, campaign_id, jdRole)
      .then((res) => {
        if (res.data.Response === 'Ok') {
          setOpen(true)
          toast.success(
            'Whats app message sending ..., we notify you with delivery reports',
          )
          handleWhatsapp(false)
        } else {
          setOpen(true)
          toast.error('Not able to send Whats app messages, Please try again')
          handleWhatsapp(false)
        }
      })
      .catch((e) => {
        setOpen(true)
        toast.error('Not able to send Whats app messages, Please try again')
        handleWhatsapp(false)
      })
    //when done lets close dialog
  }
  //****************************************************************************************************
  return (
    <div>
      <Grid container spacing={3} className="mb-3">
        <Grid item xs={12} sm={3} className={classes.myCard}>
          <Card
            className="play-card p-sm-24 bg-paper"
            elevation={3}
            onClick={() => handleMeeting(true)}
          >
            <div className="flex items-center">
              <Icon className={classes.icon}>duo</Icon>
              <div className="ml-3">
                <small className="text-muted text-primary">
                  Schedule Meeting
                </small>
                <h6 className="m-0 mt-1 font-medium text-small">Google Meet</h6>
              </div>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3} className={classes.myCard}>
          <Card
            className="play-card p-sm-24 bg-paper"
            elevation={3}
            onClick={() => handleEmail(true)}
          >
            <div className="flex items-center">
              <Icon className={classes.icon}>email</Icon>
              <div className="ml-3">
                <small className="text-muted text-primary">Send Email</small>
                <h6 className="m-0 mt-1 font-medium text-small">
                  Send Email to Candidate
                </h6>
              </div>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3} className={classes.myCard}>
          <Card
            className="play-card p-sm-24 bg-paper"
            elevation={3}
            onClick={() => handleSMS(true)}
          >
            <div className="flex items-center">
              <Icon className={classes.icon}>sms</Icon>
              <div className="ml-3">
                <small className="text-muted text-primary">Send SMS</small>
                <h6 className="m-0 mt-1 font-medium text-small">
                  Send SMS to Candidate
                </h6>
              </div>
            </div>
          </Card>
        </Grid>

        <Grid item xs={12} sm={3} className={classes.myCard}>
          <Card
            className="play-card p-sm-24 bg-paper"
            elevation={3}
            onClick={() => handleWhatsapp(true)}
          >
            <div className="flex items-center">
              <Icon className={classes.icon}>whatsapp</Icon>
              <div className="ml-3">
                <small className="text-muted text-primary">Whatsapp Bot</small>
                <h6 className="m-0 mt-1 font-medium text-small">
                  Use collect info automatically
                </h6>
              </div>
            </div>
          </Card>
        </Grid>
      </Grid>
      {/* Schedule Meeting */}

      {/****************************** Schedule Meeting **************************************/}
      <ScheduleMeeting
        isOpen={showMeeting}
        handleClose={(e) => handleMeeting(false)}
        attendees={attendees}
        data={{
          name: name,
          role: jdRole,
          status: 'Not contacted',
          lead_id: lead_id,
          campaign_id: campaign_id,
        }}
        options={['Not contacted', 'Contacted', '1st round']}
        classes={classes}
      />

      {/****************************** SEND EMAIL Dialog **************************************/}
      <SendEmailDialog
        isOpen={showEmail}
        handleClose={(e) => handleEmail(false)}
        data={{
          name: name,
          role: jdRole,
          status: 'Not contacted',
          lead_id: lead_id,
          campaign_id: campaign_id,
          token: props.props.token,
        }}
        options={['Not contacted', 'Contacted', '1st round']}
        classes={classes}
      />

      {/****************************** SEND SMS Dialog **************************************/}
      <SendSMSDialog
        isOpen={showSMS}
        handleClose={(e) => handleSMS(false)}
        data={{
          name: name,
          role: jdRole,
          status: 'Not contacted',
          lead_id: lead_id,
          campaign_id: campaign_id,
          token: props.props.token,
        }}
        options={['Not contacted', 'Contacted', '1st round']}
        classes={classes}
      />

      {/****************************** SEND Whatsapp Dialog **************************************/}
      <SendWhatsAppDialog
        isOpen={showWhatsapp}
        handleClose={(e) => handleWhatsapp(false)}
        handleSend={handleWhatappSend}
        data={{
          name: name,
          role: jdRole,
          status: 'Not contacted',
          lead_id: lead_id,
          campaign_id: campaign_id,
        }}
        options={['Not contacted', 'Contacted', '1st round']}
        classes={classes}
      />
    </div>
  )
}

export default InteractionTypeCard
