import instance from './API'

//https://testserver.10xscale.in/api/chat/all?campaign_id=51&offset=0&limit=100

export function getChatAll(campaign_id, offSet, limit, channel) {
  const config = {
    headers: {
      // 'Authorization': "Token " + apptoken,
      'Content-Type': 'application/json',
    },
    params: {
      campaign_id,
      offset: offSet,
      limit,
      channel,
    },
  }

  return instance.get('chat/all/', config)
}

export function downLoadFileInChat(chat_id) {
  const config = {
    params: {
      chat_id,
    },
    responseType: 'blob',
  }
  return instance.get('chat/file/download/', config)
}

export function getSearchChat(
  query,
  offSet,
  limit,
  channel,
  campaign_id = 'all',
) {
  console.log('getSearchChat', query, offSet, limit, channel, campaign_id)
  const config = {
    params: {
      query,
      offset: offSet,
      limit,
      channel,
      campaign_id,
    },
  }

  return instance.get('chat/search/', config)
}

export function getChatDetails(lead_id, offSet, limit, channel = 'whatsapp') {
  const config = {
    params: {
      lead_id,
      offset: offSet,
      limit,
      channel,
    },
  }

  return instance.get('chat/details/', config)
}
export function postSendMessage(
  lead_id,
  campaign_id,
  template_id,
  channel,
  subject,
  tags,
) {
  const formData = new FormData()
  formData.append('lead_id', lead_id)
  formData.append('campaign_id', campaign_id)
  formData.append('template_id', template_id)
  formData.append('channel', channel)
  formData.append('subject', subject)
  formData.append('tags', tags)

  return instance.post('chat/chat/', formData, {})
}
export function postSendWhatsppTemplateMsg(
  campaign_id,
  lead_ids,
  message,
  statusID,
  templateValue,
) {
  console.log(
    'API_postSendWhatsppTemplateMsg',
    campaign_id,
    lead_ids,
    message,
    statusID,
    templateValue,
  )

  const formData = new FormData()
  formData.append('campaign_id', campaign_id)
  formData.append('lead_ids', lead_ids)
  formData.append('message', message)
  formData.append('status_id', statusID)
  formData.append('template_id', templateValue)

  return instance.post('/send/whatsapp/', formData)
}

export function putSendMessage(
  chatID,
  chatDetailsID,
  message,
  subject,
  channel,
  lead_id,
  campaign_id,
  phone_number,
) {
  const formData = new FormData()
  formData.append('chatID', chatID)
  formData.append('chatDetailsID', chatDetailsID)
  formData.append('subject', subject)
  formData.append('channel', channel)
  formData.append('message', message)
  formData.append('lead_id', lead_id)
  formData.append('campaign_id', campaign_id)
  formData.append('phone_number', phone_number)

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.put('chat/chat/', formData, config)
}

export function postAIHelperFile(campaign_id, fileDetails, title) {
  const formData = new FormData()
  formData.append('campaign_id', campaign_id)
  formData.append('file', fileDetails)
  formData.append('title', title)

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  return instance.post('chat/generate/summary/', formData, config)
}

export function getAIHelperFileDetails(token, offset, limit) {
  console.log('postAIHelperFile', token, offset, limit)
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      offset: offset,
      limit,
    },
  }
  return instance.get('chat/generate/summary/', config)
}

export function aiChatPendingStatus(
  campaign_id,
  is_pending,
  offset,
  limit,
  channel = 'WHATSAPP',
  token,
) {
  console.log(
    'postAIHelperFile',
    campaign_id,
    is_pending,
    offset,
    limit,
    (channel = 'WHATSAPP'),
    token,
  )
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      campaign_id,
      offset: offset,
      limit,
      channel,
      is_pending,
    },
  }
  return instance.get('chat/ai/chats/', config)
}

export function whatsAppBulkChatIds(chat_ids) {
  console.log('getAIGeneratedFile', chat_ids)
  const formData = new FormData()
  formData.append('chat_ids', chat_ids)

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.post('chat/whatapp/bulk/', formData, config)
}

export function getAIGeneratedFile(query, offset, limit, token) {
  console.log('getAIGeneratedFile', query, offset, limit, token)
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      query,
      offset: offset,
      limit,
    },
  }

  return instance.get('chat/generate/summary/search/', config)
}

export function manualOtherContactsSearch(query, campaign_id, offset, limit) {
  console.log('manualOtherContactsSearch', query, campaign_id, offset, limit)
  const config = {
    params: {
      query,
      campaign_id,
      offset: offset,
      limit,
    },
  }

  return instance.get('chat/search/contacts/', config)
}

export function fetchSummaryData(cv, jd) {
  console.log('manualOtherContactsSearch', cv, jd)
  const config = {
    params: {
      cv,
      jd,
    },
  }

  return instance.get('/cv/summary/generate/', config)
}

export function createSummary(cv, jd) {
  console.log('manualOtherContactsSearch', cv, jd)

  const formData = new FormData()
  formData.append('cv', cv)
  formData.append('jd', jd)
  // Need to be Removed
  return instance.post('', formData, {
    responseType: 'stream',
  })
}

export function updateSummary(id) {
  console.log('manualOtherContactsSearch', id)
  const formData = new FormData()
  formData.append('id', id)

  return instance.put('/cv/summary/generate/', formData)
}
