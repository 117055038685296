const initialState = {
  data: [
    {
      database: '',
      host: '',
      password: '',
      port: '',
      user_name: '',
      referrals: '',
      last_completed_times: '',
      bulk_folder_location: '',
      campaign_name: '',
      naukri_folder: '',
    },
  ],
  fetchTime: [Date.now()],
  error: [''],
  isLoading: [false],
  redirect: [false],
  path: [''],
  campaign_id: [],
}

const JDSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'JD_SETTINGS_DATA':
      console.log(action)
      var indexSD = state.campaign_id.indexOf(action.campaign_id)
      // if campaign_id.length === 0, then this is the first item... , here push it in campaign_id and the other values replace with action
      // if campaign_id.length > 1, then add campaign_id and push the other items

      if (state.campaign_id.length === 0) {
        let campaign_ids = Object.assign({}, state).campaign_id
        campaign_ids.push(action.jdid)

        return {
          ...state,
          data: [
            {
              user_name: action.data.user_name,
              password: action.data.password,
              host: action.data.host,
              port: action.data.port,
              database: action.data.database,
              referrals: action.data.referrals,
              bulk_folder_location: action.data.bulk_folder_location,
              last_completed_times: action.data.last_completed_times,
              campaign_name: action.data.campaign_name,
              naukri_folder: action.data.naukri_folder,
            },
          ],
          fetchTime: [Date.now()],
          isLoading: [false],
          error: [''],
          redirect: [false],
          path: [''],
          campaign_id: campaign_ids,
        }
      } else if (indexSD === -1) {
        let campaign_ids = Object.assign({}, state).campaign_id
        campaign_ids.push(action.jdid)

        let data = Object.assign({}, state).data
        data.push({
          user_name: action.data.user_name,
          password: action.data.password,
          host: action.data.host,
          port: action.data.port,
          database: action.data.database,
          referrals: action.data.referrals,
          bulk_folder_location: action.data.bulk_folder_location,
          last_completed_times: action.data.last_completed_times,
          campaign_name: action.data.campaign_name,
          naukri_folder: action.data.naukri_folder,
        })
        let fetchTime = Object.assign({}, state).fetchTime
        fetchTime.push(Date.now())
        let isLoading = Object.assign({}, state).isLoading
        isLoading.push(false)
        let error = Object.assign({}, state).error
        error.push('')
        let redirect = Object.assign({}, state).redirect
        redirect.push(false)
        let path = Object.assign({}, state).path
        path.push('')
        return {
          ...state,
          data: data,
          fetchTime: fetchTime,
          isLoading: isLoading,
          error: error,
          redirect: redirect,
          path: path,
          campaign_id: campaign_ids,
        }
      } else {
        return {
          ...state,
        }
      }

    case 'JD_SETTINGS_ERROR':
      return {
        ...state,
        data: initialState.data,
        fetchTime: [Date.now()],
        isLoading: [false],
        error: [action.error],
        campaign_id: [action.jdid],
      }

    case 'JD_SETTINGS_CLEAR':
      return {
        ...state,
        data: initialState.data,
        fetchTime: initialState.fetchTime,
        error: initialState.error,
        isLoading: initialState.isLoading,
        redirect: initialState.redirect,
        path: initialState.path,
        campaign_id: initialState.campaign_id,
      }
    case 'JD_SETTINGS_RESET':
      let x = {
        data: [
          {
            database: '',
            host: '',
            password: '',
            port: '',
            user_name: '',
            referrals: '',
            last_completed_times: '',
            bulk_folder_location: '',
            campaign_name: '',
            naukri_folder: '',
          },
        ],
        fetchTime: [Date.now()],
        error: [''],
        isLoading: [false],
        redirect: [false],
        path: [''],
        campaign_id: [],
      }
      return x
    case 'JD_SETTINGS_REDIRECT':
      var index = state.campaign_id.indexOf(action.campaign_id)
      return {
        ...state,
        data: state.data.map((k, i) => {
          if (i === index) {
            return initialState.data
          } else {
            return k
          }
        }),
        error: state.error.map((k, i) => {
          if (i === index) {
            return action.error
          } else {
            return k
          }
        }),
        isLoading: state.isLoading.map((k, i) => {
          if (i === index) {
            return true
          } else {
            return k
          }
        }),
        redirect: state.redirect.map((k, i) => {
          if (i === index) {
            return true
          } else {
            return k
          }
        }),
        path: state.path.map((k, i) => {
          if (i === index) {
            return action.path
          } else {
            return k
          }
        }),
      }
    case 'JD_SETTINGS_REMOVE_ELEMENT':
      var indexJD = state.campaign_id.indexOf(action.campaign_id)
      return {
        ...state,
        data: state.data.filter((k, i) => {
          return i !== indexJD
        }),
        fetchTime: state.fetchTime.filter((k, i) => {
          return i !== indexJD
        }),
        error: state.error.filter((k, i) => {
          return i !== indexJD
        }),
        isLoading: state.isLoading.filter((k, i) => {
          return i !== index
        }),
        path: state.path.filter((k, i) => {
          return i !== indexJD
        }),
        redirect: state.redirect.filter((k, i) => {
          return i !== indexJD
        }),
        campaign_id: state.campaign_id.filter((k, i) => {
          return i !== indexJD
        }),
      }
    default:
      return state
  }
}

export default JDSettingsReducer
