import * as jd_api from '@src/api/JdApi'

export async function getLiveScrapingData(props) {
  let token = props.token
  let res = await jd_api.getLiveScarping(token)
  let data = res.data.Data

  if (data.length > 0) {
    let data_to_show = []
    data.map((k, _) => {
      if (!Object.keys(data_to_show).includes(k.campaign_id?.toString())) {
        data_to_show[k.campaign_id] = {}
        data_to_show[k.campaign_id]['campaign_name'] = k.campaign_name
        data_to_show[k.campaign_id]['status'] = k.cv_scraping_status
        data_to_show[k.campaign_id]['created_at'] = k.created_at
        data_to_show[k.campaign_id]['updated_at'] = k.updated_at
        if (k.source_name === 'NAUKRI') {
          data_to_show[k.campaign_id]['naukri'] = k.source_value.toString()
        } else if (k.source_name === 'DB') {
          data_to_show[k.campaign_id]['db'] = k.source_value.toString()
        } else if (k.source_name === 'LINKEDIN') {
          data_to_show[k.campaign_id]['linkedin'] = k.source_value.toString()
        } else if (k.source_name === 'REFERRAL') {
          data_to_show[k.campaign_id]['referral'] = k.source_value.toString()
        } else if (k.source_name === 'CLOUD') {
          data_to_show[k.campaign_id]['cloud'] = k.source_value.toString()
        }
      } else {
        if (k.source_name === 'NAUKRI') {
          data_to_show[k.campaign_id]['naukri'] = k.source_value.toString()
        } else if (k.source_name === 'DB') {
          data_to_show[k.campaign_id]['db'] = k.source_value.toString()
        } else if (k.source_name === 'LINKEDIN') {
          data_to_show[k.campaign_id]['linkedin'] = k.source_value.toString()
        } else if (k.source_name === 'REFERRAL') {
          data_to_show[k.campaign_id]['referral'] = k.source_value.toString()
        } else if (k.source_name === 'CLOUD') {
          data_to_show[k.campaign_id]['cloud'] = k.source_value.toString()
        }
      }
    })

    return Object.keys(data_to_show).map((k, i) => {
      return {
        name: data_to_show[k].campaign_name,
        id: k,
        status: data_to_show[k].status,
        naukri: data_to_show[k].naukri ?? 0,
        db: data_to_show[k].db ?? 0,
        linkedin: data_to_show[k].linkedin ?? 0,
        referrals: data_to_show[k].referral ?? 0,
        cloud: data_to_show[k].cloud ?? 0,
        created_at: data_to_show[k].created_at,
        updated_at: data_to_show[k].updated_at,
      }
    })
  }

  return []
}

export const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    // backgroundColor: theme.palette.background.paper,
  },
  typo: {
    fontFamily: 'Cabin',
    fontWeight: 700,
    flexGrow: 1,
  },
  settingsIcon: {
    // marginRight: theme.spacing(2),
  },
  ExitToAppIcon: {},
  fab: {
    position: 'fixed',
    // bottom: theme.spacing(2),
    //right: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  paper: {
    // marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    // margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    //  marginTop: theme.spacing(1),
  },
  submit: {
    // margin: theme.spacing(3, 0, 2),
  },

  link: {
    '& > * + *': {
      //marginLeft: theme.spacing(2),
    },
    color: '#FFFFFF',
  },

  my_link: {
    color: '#FFFFFF',
  },

  chat_table: {
    minWidth: 650,
  },
  chat_section: {
    width: '100%',
    height: '80vh',
    'overflow-y': 'scroll',
  },
  chat_headBG: {
    backgroundColor: '#e0e0e0',
  },
  chat_borderRight500: {
    borderRight: '1px solid #e0e0e0',
  },
  chat_messageArea: {
    height: '100%',
    overflowY: 'auto',
  },
  icon: {
    fontSize: '44px',
    opacity: 0.6,
    // color: theme.palette.primary.main
  },
})
