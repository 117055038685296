import instance from './API'

//get files
export function cvmatch(token, campaign_id, lead_id) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      campaign_id,
      lead_id,
    },
  }
  // Need to be Removed
  return instance.get('cv/jdmatch/', config)
}

//get files
export function cvinteract(token, lead_id) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      lead_id,
    },
  }

  return instance.get('lead/contact/', config)
}
export function postCvInteract(
  token,
  lead_id,
  campaign_id,
  action,
  oldStatus,
  newStatus,
  comments,
  date,
) {
  const formData = new FormData()
  formData.append('campaign_id', campaign_id)
  formData.append('lead_id', lead_id)
  formData.append('action', action)
  formData.append('oldStatus', oldStatus)
  formData.append('newStatus', newStatus)
  formData.append('comments', comments)
  formData.append('date', date)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('lead/contact/', formData, config)
}

//get files
export function deleteCvInteract(token, id) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    data: {
      id,
    },
  }

  return instance.delete('lead/contact/', config)
}

//get files
export function cvanalysis(token, lead_id) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      lead_id,
    },
  }

  return instance.get('lead/analysis/', config)
}

export function uploadCV(token, lead_id, campaign_id, file) {
  const formData = new FormData()
  formData.append('campaign_id', campaign_id)
  formData.append('lead_id', lead_id)
  formData.append('file', file)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }
  // Need to be Removed
  return instance.post('cv/jdmatch/', formData, config)
}

export function updateEmailPhone(token, lead_id, type, value) {
  const formData = new FormData()
  formData.append('lead_id', lead_id)
  if (type === 'Email') {
    formData.append('email', value)
  } else {
    formData.append('phone', value)
  }

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('lead/details/', formData, config)
}

export function updateEmailPhoneCandidateCard(lead_id, email, phone) {
  const formData = new FormData()
  formData.append('lead_id', lead_id)
  if (phone !== '') {
    formData.append('phone', phone)
  }
  if (email !== '') {
    formData.append('email', email)
  }

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.post('lead/details/', formData, config)
}
