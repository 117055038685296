import React, { Fragment, useEffect, useState } from 'react'

import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Input,
  Modal,
  ModalFooter,
  ModalBody,
  ModalHeader,
} from 'reactstrap'

import CreatableSelect from 'react-select/creatable'
import dayjs from 'dayjs'
import { MeetingCard } from './components/meeting_card'
import {
  createSummary,
  fetchSummaryData,
  updateSummary,
} from '../../../../api/ChatApi'
import { firebaseAuth } from '../../../../firebase'
import ReactMarkdown from 'react-markdown'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import copy from 'copy-to-clipboard'
import { Box, Tooltip } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { toast } from 'react-toastify'

function AIHelper({ isOpen, isClose, campaign_id, lead_id }) {
  const toggle = () => {
    isClose(!isOpen)
  }

  const [summary, setSummary] = useState({
    summary: '',
  })

  const [isLoading, setIsLoading] = useState(false)

  async function streamFile() {}

  const handleGenerate = async () => {
    if (!campaign_id && !lead_id) {
      return
    }
    setIsLoading(true)

    try {
      const final_url = process.env.REACT_APP_SERVER + 'cv/summary/generate/'
      const idToken = (await firebaseAuth.currentUser?.getIdToken()) ?? ''
      console.log('call_evaluate', idToken, final_url)
      const formData = new FormData()
      formData.append('cv', lead_id)
      formData.append('jd', campaign_id)
      const response = await fetch(final_url, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + idToken,
        },
        body: formData,
      })
      console.log('call_evaluate', response)

      const reader = response.body.getReader()
      const decoder = new TextDecoder()
      let accumulatedChunks = ''

      setIsLoading(false)
      while (true) {
        const { done, value } = await reader.read()

        if (done) {
          console.log('Streaming response ended', accumulatedChunks)
          break
        }
        const decodedChunk = decoder.decode(value, { stream: true })
        accumulatedChunks += decodedChunk

        console.log('Streaming response_', accumulatedChunks)
        setSummary(() => ({
          summary: accumulatedChunks,
        }))
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }

  console.log('AIHelper_', isLoading, summary)

  const [isCopied, setIsCopied] = useState(false)
  const [likeStatus, setLikeStatus] = useState(false)
  const [dislikeStatus, setDislikeStatus] = useState(false)
  const handleCopyContent = () => {
    copy(summary)
    setIsCopied(true)
  }

  return (
    <div>
      <Card>
        <Fragment>
          <Modal
            isOpen={isOpen}
            toggle={toggle}
            className="modal-dialog-centered modal-refer-earn modal-lg"
            backdrop="static"
          >
            <ModalHeader className="bg-transparent w-100" toggle={toggle}>
              <h4 className="fw-bolder">AI Candidate Assessment</h4>
            </ModalHeader>
            <ModalBody>
              {isLoading ? (
                <Box
                  sx={{ display: 'flex', justifyContent: 'center' }}
                  className="text-centesr"
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {console.log('summary?.summary', summary)}
                  {summary?.summary?.length > 0 ? (
                    <div className="" style={{ position: 'relative' }}>
                      <div
                        style={{
                          padding: '35px',
                          border: '1px solid #ccc',
                          borderRadius: '5px',
                          backgroundColor: '#f9f9f9',
                          maxHeight: 'auto',
                          overflowY: 'auto',
                        }}
                      >
                        <ReactMarkdown>
                          {summary?.summary?.length > 0 ? summary?.summary : ''}
                        </ReactMarkdown>
                      </div>
                      <Tooltip
                        title={isCopied ? 'text-copied' : 'copy '}
                        arrow
                        placement="top"
                      >
                        <ContentCopyIcon
                          onClick={() => handleCopyContent(summary?.summary)}
                          color="secondary"
                          className="cursor-pointer"
                          size="small"
                          style={{
                            height: '0.9em',
                            width: '0.9em',
                            position: 'absolute',
                            right: '6em' /* Adjusted the position */,
                            top: '.7em',
                            color: '#989898',
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Like" arrow placement="top">
                        <ThumbUpIcon
                          className="cursor-pointer"
                          size="small"
                          style={{
                            height: '0.9em',
                            width: '0.9em',
                            position: 'absolute',
                            right: '4em',
                            top: '.7em',
                            color: likeStatus ? '#007bff' : '#989898',
                          }}
                          onClick={() => setLikeStatus(!likeStatus)}
                        />
                      </Tooltip>
                      <Tooltip title="Dislike" arrow placement="top">
                        <ThumbDownIcon
                          className="cursor-pointer"
                          size="small"
                          style={{
                            height: '0.9em',
                            width: '0.9em',
                            position: 'absolute',
                            right: '2em',
                            top: '.7em',
                            color: dislikeStatus ? '#007bff' : '#989898',
                          }}
                          onClick={() => setDislikeStatus(!dislikeStatus)}
                        />
                      </Tooltip>
                    </div>
                  ) : (
                    <div className="">
                      <p className="  lighten-3 fw-bolder text-center">
                        <i className=" opacity-75">
                          {' '}
                          ‟ No assessments are available. <br /> Please generate
                          assessment‟{' - '}{' '}
                          <Button
                            href="#"
                            className="outline-none border-0 transparent"
                            color="primary"
                            onClick={handleGenerate}
                          >
                            Generate Assessment
                          </Button>
                        </i>
                      </p>
                    </div>
                  )}
                </>
              )}
            </ModalBody>
            {/* <ModalFooter>
              <Button onClick={toggle} color="primary" outline className="me-1">
                Discard
              </Button>
            </ModalFooter> */}
          </Modal>
        </Fragment>
      </Card>
    </div>
  )
}

export default AIHelper
