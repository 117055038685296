// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import {
  getAllEmail,
  updateEmailLabel,
  deleteEmails,
  getEmailSearchResults,
} from '@src/api/ChatEmailApi'

export const getMails = createAsyncThunk(
  'appEmail/getMails',
  async (
    { folder_name, start, end, setNewDataLoaded },
    { rejectWithValue },
  ) => {
    try {
      const response = await getAllEmail(folder_name, start, end)
      console.log('appEmail/getMails: ', folder_name, start, end, response)
      setNewDataLoaded(true)
      return { Data: response.data.Data, folder_name: folder_name, start }
    } catch (error) {
      setNewDataLoaded(true)
      return rejectWithValue(error)
    }
  },
)

export const searchMails = createAsyncThunk(
  'appEmail/searchMails',
  async (
    { query, folder_name, start, end, setNewDataLoaded },
    { rejectWithValue },
  ) => {
    try {
      const response = await getEmailSearchResults(
        query,
        start,
        end,
        folder_name,
      )
      setNewDataLoaded(true)
      return { Data: response.data.Data, folder_name: folder_name }
    } catch (error) {
      setNewDataLoaded(true)
      return rejectWithValue(error)
    }
  },
)

export const clearAllEmails = createAsyncThunk(
  'appEmail/clearMails',
  async ({}, { rejectWithValue }) => {
    console.log('clearAllEmails ')
    try {
      return { Data: [], folder_name: '' }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const updateMails = createAsyncThunk(
  'appEmail/updateMails',
  async ({ emailIds, dataToUpdate }, { dispatch, getState }) => {
    const response = await axios.post('/apps/email/update-emails', {
      emailIds,
      dataToUpdate,
    })
    return {
      emailIds,
      dataToUpdate,
      data: response.data,
    }
  },
)

export const updateMailLabel = createAsyncThunk(
  'appEmail/updateMailLabel',
  async ({ emailID, label }, { dispatch, getState }) => {
    const response = await updateEmailLabel(emailID, label)
    if (response.data.Response !== 'Ok') return []
    let mails = getState().email.mails
    console.log('UpdatedMails', mails)
    let data = response.data.Data
    let index = -1
    mails.map((e, pos) => {
      if (e.id === data.id) {
        index = pos
      }
    })
    console.log('UpdatedMails', index)
    // TODO: now working, here crashing
    let mail = mails[index]
    mail.label = data.label
    console.log('UpdatedMails mail', mail)
    console.log('UpdatedMails final', mails)
    return mails
  },
)

export const paginateMail = createAsyncThunk(
  'appEmail/paginateMail',
  async ({ dir, emailId }) => {
    const response = await axios.get('/apps/email/paginate-email', {
      params: { dir, emailId },
    })
    return response.data
  },
)

export const selectCurrentMail = createAsyncThunk(
  'appEmail/selectCurrentMail',
  async (
    { folder_name, start, end, uid, setEmailDetailLoaded },
    { rejectWithValue },
  ) => {
    try {
      const response = await getAllEmail(folder_name, start, end, 'y', uid)
      console.log('appEmail/getMails: ', folder_name, start, end, response)
      setEmailDetailLoaded(true)
      return { Data: response.data.Data, folder_name: folder_name }
    } catch (error) {
      setEmailDetailLoaded(true)
      return rejectWithValue(error)
    }
  },
)

export const deleteMails = createAsyncThunk(
  'appEmail/deleteMails',
  async ({ emailIds, dataToUpdate }, { rejectWithValue }) => {
    try {
      const response = await deleteEmails(emailIds, dataToUpdate)
      console.log('appEmail/getMails: ', emailIds, dataToUpdate)
      return { Data: emailIds }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const appEmailSlice = createSlice({
  name: 'appEmail',
  initialState: {
    mails: {},
    params: {},
    emailsMeta: {},
    selectedMails: [],
    currentMail: null,
    folder: '',
    last_fetch_time: {},
  },
  reducers: {
    selectMail: (state, action) => {
      const selectedMails = state.selectedMails
      if (!selectedMails.includes(action.payload)) {
        selectedMails.push(action.payload)
      } else {
        selectedMails.splice(selectedMails.indexOf(action.payload), 1)
      }
      state.selectedMails = selectedMails
      console.log('STORE: DISPATCH SELECT MAIL ', action.payload)
    },
    selectAllMail: (state, action) => {
      // const selectAllMailsArr = []
      // if (action.payload) {
      //   selectAllMailsArr.length = 0
      //   state.mails.forEach(mail => selectAllMailsArr.push(mail.id))
      // } else {
      //   selectAllMailsArr.length = 0
      // }
      // state.selectedMails = selectAllMailsArr
    },
    updateFolder: (state, action) => {
      // const selectAllMailsArr = []
      // if (action.payload) {
      //   selectAllMailsArr.length = 0
      //   state.mails.forEach(mail => selectAllMailsArr.push(mail.id))
      // } else {
      //   selectAllMailsArr.length = 0
      // }
      console.log('are you here: ', action)
      return {
        ...state,
        folder: action.payload,
      }
    },
    resetSelectedMail: (state) => {
      state.selectedMails = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMails.fulfilled, (state, action) => {
        console.log('UpdatedMails from reducer', action.payload)

        var folder_name = action?.payload?.folder_name ?? ''

        if (folder_name.length == 0) {
          return
        }

        if (action.payload.start === 0) {
          state.mails[action.payload.folder_name] = action.payload.Data
        } else {
          let old_data = [...state.mails[action.payload.folder_name]]
          state.mails[action.payload.folder_name] = old_data.concat(
            action.payload.Data,
          )
        }

        state.folder = folder_name
        state.last_fetch_time[folder_name] = Date()
      })
      .addCase(getMails.rejected, (state, action) => {
        console.log('UpdatedMails from reducer', action)

        state.mails['Inbox'] = []
        state.folder = 'Inbox'
        state.last_fetch_time['Inbox'] = Date()
      })
      .addCase(searchMails.fulfilled, (state, action) => {
        console.log('UpdatedMails from reducer', action.payload)

        state.mails[action.payload.folder_name] = action.payload.Data
        state.last_fetch_time[action.payload.folder_name] = Date()
      })
      .addCase(searchMails.rejected, (state, action) => {
        console.log('UpdatedMails from reducer', action)

        state.mails['Inbox'] = []
        state.last_fetch_time['Inbox'] = Date()
      })
      .addCase(clearAllEmails.fulfilled, (state, action) => {
        console.log('UpdatedMails from reducer', action)
        state.mails = {}
        state.folder = ''
        state.emailsMeta = {}
        state.selectedMails = []
        state.currentMail = null
        state.last_fetch_time = {}
      })
      .addCase(clearAllEmails.rejected, (state, action) => {
        console.log('UpdatedMails from reducer', action)
        state.mails = {}
        state.folder = ''
        state.emailsMeta = {}
        state.selectedMails = []
        state.currentMail = null
        state.last_fetch_time = {}
      })
      .addCase(deleteMails.fulfilled, (state, action) => {
        let newData = [...state.mails[state.folder]].filter((k) => {
          console.log(
            'UpdatedMails from reducer0',
            action.payload.Data.includes(k[0].uid),
          )
          return !action.payload.Data.includes(k.uid)
        })
        console.log('UpdatedMails from reducer', action, newData)
        state.mails[state.folder] = newData
        state.last_fetch_time[state.folder] = Date()
      })
      .addCase(deleteMails.rejected, (state, action) => {
        console.log('UpdatedMails from reducer', action)
        state.last_fetch_time[state.folder] = Date()
      })
      .addCase(updateMailLabel.fulfilled, (state, action) => {
        console.log('UpdatedMails from reducer', action.payload)
        if (action.payload.length !== 0) {
          state.mails = action.payload
        }
      })
      .addCase(updateMails.fulfilled, (state, action) => {})
      .addCase(paginateMail.fulfilled, (state, action) => {
        // const data = action.payload
        // const dataIndex = state.mails.findIndex(i => i.id === data.id)
        // dataIndex === 0 ? (data.hasPreviousMail = false) : (data.hasPreviousMail = true)
        // dataIndex === state.mails.length - 1 ? (data.hasNextMail = false) : (data.hasNextMail = true)
        // state.currentMail = data
      })
      .addCase(selectCurrentMail.fulfilled, (state, action) => {
        //CURRENT DETAILS
        state.currentMail = action.payload.Data[0]
      })
  },
})

export const { selectMail, selectAllMail, resetSelectedMail, updateFolder } =
  appEmailSlice.actions

export default appEmailSlice.reducer

// // ** Redux Imports
// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
//
// // ** Axios Imports
// import axios from 'axios'
//
// export const getMails = createAsyncThunk('appEmail/getMails', async params => {
//   console.log('appEmail/getMails:2a ')
//   const response = await axios.get('/apps/email/emails', { params })
//   console.log('appEmail/getMails:2 ', response)
//   return {
//     params,
//     data: response.data
//   }
// })
//
// export const updateMails = createAsyncThunk(
//   'appEmail/updateMails',
//   async ({ emailIds, dataToUpdate }, { dispatch, getState }) => {
//     const response = await axios.post('/apps/email/update-emails', { emailIds, dataToUpdate })
//     await dispatch(getMails(getState().email.params))
//     return {
//       emailIds,
//       dataToUpdate,
//       data: response.data
//     }
//   }
// )
//
// export const updateMailLabel = createAsyncThunk(
//   'appEmail/updateMailLabel',
//   async ({ emailIds, label }, { dispatch, getState }) => {
//     const response = await axios.post('/apps/email/update-emails-label', { emailIds, label })
//     await dispatch(getMails(getState().email.params))
//     return response.data
//   }
// )
//
// export const paginateMail = createAsyncThunk('appEmail/paginateMail', async ({ dir, emailId }) => {
//   const response = await axios.get('/apps/email/paginate-email', { params: { dir, emailId } })
//   return response.data
// })
//
// export const selectCurrentMail = createAsyncThunk('appEmail/selectCurrentMail', async id => {
//   const response = await axios.get('/apps/email/get-email', { id })
//   return response.data
// })
//
// export const appEmailSlice = createSlice({
//   name: 'appEmail',
//   initialState: {
//     mails: [],
//     params: {},
//     emailsMeta: {},
//     selectedMails: [],
//     currentMail: null
//   },
//   reducers: {
//     selectMail: (state, action) => {
//       const selectedMails = state.selectedMails
//       if (!selectedMails.includes(action.payload)) {
//         selectedMails.push(action.payload)
//       } else {
//         selectedMails.splice(selectedMails.indexOf(action.payload), 1)
//       }
//       state.selectedMails = selectedMails
//     },
//     selectAllMail: (state, action) => {
//       const selectAllMailsArr = []
//       if (action.payload) {
//         selectAllMailsArr.length = 0
//         state.mails.forEach(mail => selectAllMailsArr.push(mail.id))
//       } else {
//         selectAllMailsArr.length = 0
//       }
//       state.selectedMails = selectAllMailsArr
//     },
//     resetSelectedMail: state => {
//       state.selectedMails = []
//     }
//   },
//   extraReducers: builder => {
//     builder
//       .addCase(getMails.fulfilled, (state, action) => {
//         let currMail = null
//         if (state.currentMail !== null && state.currentMail !== undefined) {
//           currMail = action.payload.data.emails.find(i => i.id === state.currentMail.id)
//         }
//         console.log('appEmail/getMail4:', action)
//         state.currentMail = currMail
//         state.params = action.payload.params
//         state.mails = action.payload.data.emails
//         state.emailsMeta = action.payload.data.emailsMeta
//       })
//       .addCase(updateMails.fulfilled, (state, action) => {
//         function updateMailData(email) {
//           Object.assign(email, action.payload.dataToUpdate)
//         }
//         state.mails.forEach(email => {
//           if (action.payload.emailIds.includes(email.id)) {
//             updateMailData(email)
//           }
//         })
//       })
//       .addCase(paginateMail.fulfilled, (state, action) => {
//         const data = action.payload
//         const dataIndex = state.mails.findIndex(i => i.id === data.id)
//         dataIndex === 0 ? (data.hasPreviousMail = false) : (data.hasPreviousMail = true)
//         dataIndex === state.mails.length - 1 ? (data.hasNextMail = false) : (data.hasNextMail = true)
//         state.currentMail = data
//       })
//       .addCase(selectCurrentMail.fulfilled, (state, action) => {
//         state.currentMail = action.payload
//       })
//   }
// })
//
// export const { selectMail, selectAllMail, resetSelectedMail } = appEmailSlice.actions
//
// export default appEmailSlice.reducer
