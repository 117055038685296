// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as api from '@src/api/ChatApi'
import * as jdsApi from '@src/api/JdHistoryApi'

// ** Axios Imports
import axios from 'axios'

let offset = 0
let limit = 100
let channel = 'WHATSAPP'

export const getChats = createAsyncThunk(
  'appChat/getChats',
  async (campaign_id) => {
    // const response = await axios.get('/apps/chat/users/profile-user')
    const response = await api.getChatAll(campaign_id, offset, limit, channel)
    return response.data
  },
)

export const selectChat = createAsyncThunk(
  'appChat/selectChat',
  async ({ lead_id, offset, limit, setIsChatLoaded }, { rejectWithValue }) => {
    // const response = await axios.get('/apps/chat/get-chat', { id })
    // await dispatch(getChatContacts())
    try {
      const response = await api.getChatDetails(lead_id, offset, limit, channel)
      console.log('whatspp chats', response)
      setIsChatLoaded(true)
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const aiChatPending = createAsyncThunk(
  'appChat/getAIChats',
  async ({ campaign_id, is_pending, token }, { rejectWithValue }) => {
    try {
      const response = await api.aiChatPendingStatus(
        campaign_id,
        is_pending,
        offset,
        limit,
        channel,
        token,
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const recentHistoryFiles = createAsyncThunk(
  'appChat/recentFiles',
  async ({ token, offset, limit }, { rejectWithValue }) => {
    try {
      const response = await api.getAIHelperFileDetails(token, offset, limit)
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const searchChat = createAsyncThunk(
  'appChat/searchChat',
  async (query) => {
    const response = await api.getSearchChat(
      query,
      offset,
      limit,
      channel,
      'all',
    )
    return response.data
  },
)

export const searchManualOther = createAsyncThunk(
  'appChat/searchManualOther',
  async ({ query, campaign_id }) => {
    console.log('searchManualOther: ', query, campaign_id)
    const response = await api.manualOtherContactsSearch(
      query,
      campaign_id,
      offset,
      limit,
    )
    return response.data
  },
)

export const getUserProfile = createAsyncThunk('appChat/getTasks', async () => {
  const response = await axios.get('/apps/chat/users/profile-user')
  console.log('getUserProfile', response.data)
  return response.data
})

export const getChatContacts = createAsyncThunk(
  'appChat/getChatContacts',
  async ({ campaign_id, start, end }, {}) => {
    const response = await jdsApi.getWAChooseTasks(
      '',
      campaign_id,
      start,
      end,
      'y',
      ' SUBSTR(phone_number, 1, 1) desc , rank asc, email desc ',
    )
    console.log('SHUDIPTO TASK', response)
    return { Data: response.data.Data, start: start }
  },
)

export const sendMsg = createAsyncThunk(
  'appChat/sendMsg',
  async (obj, { dispatch }) => {
    const response = await axios.post('/apps/chat/send-msg', { obj })
    await dispatch(selectChat(obj.contact.id))
    return response.data
  },
)

export const clearAllChats = createAsyncThunk(
  'appChat/clearChats',
  async ({}, { rejectWithValue }) => {
    console.log('clearAllEmails ')
    return { Data: [], folder_name: '' }
  },
)

export const currentChatInfo = createAsyncThunk(
  'appChat/currentChatInfo',
  async ({ item }, { rejectWithValue }) => {
    console.log('#SDT currentChatInfo ', item)
    try {
      return { Data: item }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const updateJDname = createAsyncThunk(
  'appChat/updateJDname',
  async ({ campaign_name }, { rejectWithValue }) => {
    console.log('updateJDname: ', campaign_name)
    try {
      return { Data: campaign_name }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const appChatSlice = createSlice({
  name: 'appChat',
  initialState: {
    chats: [],
    contacts: [],
    userProfile: {},
    selectedUserChat: [],
    selectedUserInfo: {},
    campaign_name: 'all',
    total_records: 0,
    aiChatDetails: [],
    recentFilesArray: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.userProfile = action.payload
      })
      .addCase(getChats.fulfilled, (state, action) => {
        let lead_ids_covered = []
        let res = action.payload.Data

        res = res.filter((k) => {
          if (lead_ids_covered.includes(k.lead_id)) {
            return false
          } else {
            lead_ids_covered.push(k.lead_id)
            return true
          }
        })

        console.log('action.payload.Data: ', action.payload.Data)
        state.chats = res
      })
      .addCase(aiChatPending.fulfilled, (state, action) => {
        console.log('aiChatPending', action)
        if (action?.payload?.Response == 'Ok') {
          state.aiChatDetails = action?.payload?.Data ?? []
        } else {
          state.aiChatDetails = []
        }
      })
      .addCase(aiChatPending.rejected, (state, action) => {
        console.log('aiChatPending', action)
        state.aiChatDetails = []
      })
      .addCase(recentHistoryFiles.fulfilled, (state, action) => {
        console.log('recentHistoryFiles', action)
        if (action?.payload?.Response == 'Ok') {
          state.recentFilesArray = action?.payload?.Data ?? []
        } else {
          state.recentFilesArray = []
        }
      })
      .addCase(searchChat.fulfilled, (state, action) => {
        state.chats = action.payload.Data
        console.log('SEARCH CHAT ', state, ' ----- ', action.payload.Data)
      })
      .addCase(searchManualOther.fulfilled, (state, action) => {
        state.chats = action.payload.Data
        console.log('SEARCH CHAT JD ', state, ' ----- ', action.payload.Data)
      })
      .addCase(recentHistoryFiles.rejected, (state, action) => {
        console.log('aiChatPending', action)
        state.recentFilesArray = []
      })

      .addCase(getChatContacts.fulfilled, (state, action) => {
        console.log('appChat extraReducers: ', action)
        state.contacts = action.payload.Data.data
        state.total_records = action.payload.Data?.total?.[0][0]
        console.log('appChat extraReducers: ', state)
      })

      .addCase(selectChat.fulfilled, (state, action) => {
        console.log('appChat extraReducers: ', action)
        if (action?.payload?.Response == 'Ok') {
          state.selectedUserChat = action?.payload?.Data ?? []
        } else {
          state.selectedUserChat = []
        }
      })
      .addCase(selectChat.rejected, (state, action) => {
        console.log('appChat extraReducers: ', action)
        state.selectedUserChat = []
      })
      .addCase(currentChatInfo.fulfilled, (state, action) => {
        console.log('appChat extraReducers: ', action)
        state.selectedUserInfo = action?.payload?.Data ?? {}
      })
      .addCase(currentChatInfo.rejected, (state, action) => {
        console.log('appChat extraReducers: ', action)
        state.selectedUserInfo = {}
      })
      .addCase(updateJDname.fulfilled, (state, action) => {
        console.log('appChat extraReducers: ', action)
        state.campaign_name = action?.payload?.Data ?? {}
        state.total_records = 0
        state.selectedUserChat = []
        state.selectedUserInfo = {}
        state.chats = []
      })
      .addCase(updateJDname.rejected, (state, action) => {
        console.log('appChat extraReducers: ', action)
        state.campaign_name = ''
        state.total_records = 0
        state.selectedUserChat = []
        state.selectedUserInfo = {}
        state.chats = []
      })
      .addCase(clearAllChats.fulfilled, (state, action) => {
        console.log('appChat extraReducers: ', action)

        state.chats = []
        state.contacts = []
        state.userProfile = {}
        state.selectedUserChat = []
        state.selectedUserInfo = {}
        state.campaign_name = 'all'
        state.total_records = 0
        state.aiChatDetails = []
      })
      .addCase(clearAllChats.rejected, (state, action) => {
        console.log('appChat extraReducers: ', action)

        state.chats = []
        state.contacts = []
        state.userProfile = {}
        state.selectedUserChat = []
        state.selectedUserInfo = {}
        state.campaign_name = 'all'
        state.total_records = 0
        state.aiChatDetails = []
      })
  },
})

export default appChatSlice.reducer
