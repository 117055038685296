import React from 'react'
import CandidateDetailsMainContainer from '@src/views/CandidateDetails/CandidateDetailsMainContainer'

const initialState = {
  data: [],
  fetch_data: [],
  already_tab_open_index: [],
  fetchTime: [],
  error: [],
  isLoading: [],
  is_fetching_done: [],
  redirect: [],
  path: [],
  campaign_id: [],
  page_num: [],
  rowsPerPage: [],
}

const CandidateDetailReducer = (state = initialState, action) => {
  let index = ''
  switch (action.type) {
    case 'CANDIDATE_DETAIL_ADDED':
      index = state.campaign_id
        .map((k) => k.toString())
        .indexOf(action.campaign_id.toString())
      // If jd is not present, then create a new flow
      // If jd is present, then add to the existing flow
      console.log('Candidate Details Reducer: ', action)
      if (index === -1 && state.campaign_id.length === 0) {
        //we are going inside the first time!
        let campaign_ids = Object.assign({}, state).campaign_id
        campaign_ids.push(action.campaign_id)
        let data = [
          [
            <CandidateDetailsMainContainer
              id={action.id}
              name={action.name}
              // token={
              //     action.token
              // }
              campaign_id={action.campaign_id}
              email={action.email}
              task_id={action?.task_id ?? ''}
              task_name={action?.task_name ?? ''}
              jdName={action?.campaign_name ?? ''}
            />,
            // <Resume id={action.id}
            //         name={
            //             action.name
            //         }
            //         token={
            //             action.token
            //         }
            //         campaign_id={
            //             action.campaign_id
            //         }
            //         email={
            //             action.email
            //         }
            // />
          ],
        ]
        console.log('Candidate Details Reducer: ', data)
        let fetch_data = [[]]
        let already_tab_open_index = [-1]
        let fetchTime = [Date.now()]
        let error = ['']
        let isLoading = [false]
        let is_fetching_done = [false]
        let redirect = [false]
        let path = ['']
        let page_num = [0]
        let rowsPerPage = [10]
        return {
          ...state,
          campaign_id: campaign_ids,
          data: data,
          fetch_data: fetch_data,
          already_tab_open_index: already_tab_open_index,
          fetchTime: fetchTime,
          error: error,
          isLoading: isLoading,
          is_fetching_done: is_fetching_done,
          redirect: redirect,
          path: path,
          page_num: page_num,
          rowsPerPage: rowsPerPage,
        }
      } else if (index === -1) {
        let campaign_ids = Object.assign({}, state).campaign_id
        campaign_ids.push(action.campaign_id)
        let data = Object.assign({}, state).data
        data.push([
          <CandidateDetailsMainContainer
            id={action.id}
            name={action.name}
            // token={
            //     action.token
            // }
            campaign_id={action.campaign_id}
            email={action.email}
            task_id={action?.task_id ?? ''}
            task_name={action?.task_name ?? ''}
            jdName={action?.campaign_name ?? ''}
          />,
          // <Resume id={action.id}
          //         name={
          //             action.name
          //         }
          //         token={
          //             action.token
          //         }
          //         campaign_id={
          //             action.campaign_id
          //         }
          //         email={
          //             action.email
          //         }
          // />
        ])
        let fetch_data = Object.assign({}, state).fetch_data
        fetch_data.push([])
        let already_tab_open_index = Object.assign(
          {},
          state,
        ).already_tab_open_index
        already_tab_open_index.push(-1)
        let fetchTime = Object.assign({}, state).fetchTime
        fetchTime.push(Date.now())
        let error = Object.assign({}, state).error
        error.push('')
        let isLoading = Object.assign({}, state).isLoading
        isLoading.push(false)
        let is_fetching_done = Object.assign({}, state).is_fetching_done
        is_fetching_done.push(false)
        let redirect = Object.assign({}, state).redirect
        redirect.push(false)
        let path = Object.assign({}, state).path
        path.push('')
        let page_num = Object.assign({}, state).page_num
        page_num.push(0)
        let rowsPerPage = Object.assign({}, state).rowsPerPage
        rowsPerPage.push(0)
        return {
          ...state,
          campaign_id: campaign_ids,
          data: data,
          fetch_data: fetch_data,
          already_tab_open_index: already_tab_open_index,
          fetchTime: fetchTime,
          error: error,
          isLoading: isLoading,
          is_fetching_done: is_fetching_done,
          redirect: redirect,
          path: path,
          page_num: page_num,
          rowsPerPage: rowsPerPage,
        }
      } else {
        let data = Object.assign({}, state).data
        data[index].push(
          <CandidateDetailsMainContainer
            id={action.id}
            name={action.name}
            // token={
            //     action.token
            // }
            campaign_id={action.campaign_id}
            email={action.email}
            task_id={action?.task_id ?? ''}
            task_name={action?.task_name ?? ''}
            jdName={action?.campaign_name ?? ''}
          />,

          // < Resume id={
          //     action.id
          // }
          //          name={
          //              action.name
          //          }
          //          token={
          //              action.token
          //          }
          //          campaign_id={
          //              action.campaign_id
          //          }
          //          email={
          //              action.email
          //          }
          // />
        )
        let fetchTime = Object.assign({}, state).fetchTime
        fetchTime[index] = Date.now()
        let isLoading = Object.assign({}, state).isLoading
        isLoading[index] = false
        let error2 = Object.assign({}, state).error
        error2[index] = ''
        return {
          ...state,
          data: data,
          fetchTime: fetchTime,
          isLoading: isLoading,
          error: error2,
        }
      }
    case 'CANDIDATE_DETAIL_REMOVED':
      index = state.campaign_id
        .map((k) => k.toString())
        .indexOf(action.campaign_id.toString())
      let data = [...state.data]
      data[index] = data[index].filter((e, v) => {
        console.log('CANDIDATE_DETAIL_REMOVED', v, e.props, action.id)
        return v.toString() !== action.id.toString()
      })
      console.log(
        'CANDIDATE_DETAIL_REMOVED ORIG',
        data,
        ' - ',
        index,
        ' - ',
        action,
      )

      let fetchTime = Object.assign({}, state).fetchTime
      fetchTime[index] = Date.now()
      let isLoading = Object.assign({}, state).isLoading
      isLoading[index] = false
      var error3 = Object.assign({}, state).error
      error3[index] = ''
      return {
        ...state,
        data: data,
        fetchTime: fetchTime,
        isLoading: isLoading,
        error: error3,
      }

    case 'CANDIDATE_FETCHING_DATA_DONE':
      index = state.campaign_id
        .map((k) => k.toString())
        .indexOf(action.campaign_id.toString())
      let is_fetching_done = Object.assign({}, state).is_fetching_done
      is_fetching_done[index] = action.is_fetching_done
      return {
        ...state,
        is_fetching_done: is_fetching_done,
      }
    case 'CANDIDATE_DETAIL_INIT':
      console.log('Are you inside')
      return {
        ...state,
        data: [[]],
        fetch_data: [[]],
        already_tab_open_index: [-1],
        fetchTime: [Date.now()],
        error: [''],
        isLoading: [false],
        redirect: [false],
        is_fetching_done: [false],
        path: [''],
        campaign_id: [0],
      }
    case 'CANDIDATE_DETAIL_CLEARALL':
      let x = {
        data: [],
        fetch_data: [],
        already_tab_open_index: [],
        fetchTime: [],
        error: [],
        isLoading: [],
        is_fetching_done: [],
        redirect: [],
        path: [],
        campaign_id: [],
        page_num: [],
        rowsPerPage: [],
      }
      console.log('ex: ', x)
      return x
    case 'CANDIDATE_DETAIL_ERROR':
      index = state.campaign_id
        .map((k) => k.toString())
        .indexOf(action.campaign_id.toString())
      var error4 = Object.assign({}, state).error
      error4[index] = action.error
      return {
        ...state,
        error: error4,
      }

    case 'CANDIDATE_DETAIL_REDIRECT':
      index = state.campaign_id
        .map((k) => k.toString())
        .indexOf(action.campaign_id.toString())
      var error5 = Object.assign({}, state).error
      error5[index] = action.error
      let isLoading_re = Object.assign({}, state).isLoading
      isLoading_re[index] = action.isLoading
      return {
        ...state,
        error: error5,
        isLoading: isLoading_re,
      }
    case 'EVALUATEJD_PAGE_TAB_INDEX':
      try {
        index = state.campaign_id
          .map((k) => k.toString())
          .indexOf(action.campaign_id.toString())
      } catch (error) {
        return state
      }
      let already_tab_open_index = Object.assign(
        {},
        state,
      ).already_tab_open_index
      already_tab_open_index[index] = action.tab_id
      return {
        ...state,
        already_tab_open_index: already_tab_open_index,
      }
    case 'UPDATE_FETCH_DATA':
      index = state.campaign_id
        .map((k) => k.toString())
        .indexOf(action.campaign_id.toString())
      let fetch_data = Object.assign({}, state).fetch_data
      fetch_data[index] = action.data
      return {
        ...state,
        fetch_data: fetch_data,
      }
    case 'UPDATE_PAGE_NUM':
      index = state.campaign_id
        .map((k) => k.toString())
        .indexOf(action.campaign_id.toString())
      let page_num = Object.assign({}, state).page_num
      page_num[index] = action.page_num
      let rowsPerPage = Object.assign({}, state).rowsPerPage
      rowsPerPage[index] = action.rowsPerPage
      return {
        ...state,
        page_num: page_num,
        rowsPerPage: rowsPerPage,
      }
    case 'INIT_PAGE_NUM':
      try {
        index = state.campaign_id
          .map((k) => k.toString())
          .indexOf(action.campaign_id.toString())
      } catch (error) {}
      // let page_num_init = Object.assign({}, state).page_num
      // page_num_init[index] = 0
      // let rowsPerPage_init = Object.assign({}, state).rowsPerPage
      // rowsPerPage_init[index] = 10
      // return {
      //     ...state,
      //     page_num: page_num_init,
      //     rowsPerPage: rowsPerPage_init
      // }
      return {
        ...state,
      }
    case 'UPDATE_JD_ID_CANDIDATE':
      console.log('inside before: ', state.campaign_id, action.campaign_id)
      try {
        index = state.campaign_id
          .map((k) => k.toString())
          .indexOf(action.campaign_id.toString())
      } catch (error) {
        console.log('errorrr: ', error)
        return state
      }

      // If jd is not present, then create a new flow
      // If jd is present, then add to the existing flow
      console.log('index: ', index)
      if (index === -1) {
        //we are going inside the first time!
        let campaign_ids = Object.assign({}, state).campaign_id
        campaign_ids.push(action.campaign_id)
        let data = Object.assign({}, state).data
        data.push([])
        let fetch_data = Object.assign({}, state).fetch_data
        fetch_data.push([])
        let already_tab_open_index = Object.assign(
          {},
          state,
        ).already_tab_open_index
        already_tab_open_index.push(-1)
        let fetchTime = Object.assign({}, state).fetchTime
        fetchTime.push(Date.now())
        let error = Object.assign({}, state).error
        error.push('')
        let isLoading = Object.assign({}, state).isLoading
        isLoading.push(false)
        let is_fetching_done = Object.assign({}, state).is_fetching_done
        is_fetching_done.push(false)
        let redirect = Object.assign({}, state).redirect
        redirect.push(false)
        let path = Object.assign({}, state).path
        path.push('')
        let page_num = Object.assign({}, state).page_num
        page_num.push(0)
        let rowsPerPage = Object.assign({}, state).rowsPerPage
        rowsPerPage.push(10)
        return {
          ...state,
          campaign_id: campaign_ids,
          data: data,
          fetch_data: fetch_data,
          already_tab_open_index: already_tab_open_index,
          fetchTime: fetchTime,
          error: error,
          isLoading: isLoading,
          is_fetching_done: is_fetching_done,
          redirect: redirect,
          path: path,
          page_num: page_num,
          rowsPerPage: rowsPerPage,
        }
      } else {
        return state
      }
    case 'JD_CANDIDATE_REMOVE_ELEMENT':
      console.log('JD', state.campaign_id, action.campaign_id)
      index = state.campaign_id.indexOf(action.campaign_id)
      // TODO: handle index -1
      return {
        ...state,
        data: state.data.filter((k, i) => {
          return i !== index
        }),
        fetch_data: state.fetch_data.filter((k, i) => {
          return i !== index
        }),
        already_tab_open_index: state.already_tab_open_index.filter((k, i) => {
          return i !== index
        }),
        fetchTime: state.fetchTime.filter((k, i) => {
          return i !== index
        }),
        error: state.error.filter((k, i) => {
          return i !== index
        }),
        isLoading: state.isLoading.filter((k, i) => {
          return i !== index
        }),
        campaign_id: state.campaign_id.filter((k, i) => {
          return i !== index
        }),
        is_fetching_done: state.is_fetching_done.filter((k, i) => {
          return i !== index
        }),
        redirect: state.redirect.filter((k, i) => {
          return i !== index
        }),
        path: state.path.filter((k, i) => {
          return i !== index
        }),
        page_num: state.page_num.filter((k, i) => {
          return i !== index
        }),
        rowsPerPage: state.rowsPerPage.filter((k, i) => {
          return i !== index
        }),
      }
    default:
      return state
  }
}

export default CandidateDetailReducer
