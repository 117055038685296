const initialState = {
  token: '',
  uuid: '',
  user_id: '',
  error: '',
  isAuth: false,
  fullname: '',
  role: '',
  emp_ids: [],
  emp_uuids: [],
  emp_names: {},
  emp_mappings: {},
  emp_names_mapping: {},
  emp_urls: {},
  email_folders: [],
  personal_email_folders: [],
  is_logged: 0,
  existing_password: '',
  sibling_ids: [],
  sibling_names: {},
  sibling_urls: {},
  company_id: '',
  journeyMappings: [],
  tabNames: [],
  linkedin_id: '',
}

const LoginReducers = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_SAVE':
      return {
        ...state,
        token: action.token,
        uuid: action.uuid,
        user_id: action.user_id,
        error: action.error,
        isAuth: action.isAuth,
        fullname: action.fullname,
        role: action.role,
        emp_ids: action.emp_ids,
        emp_uuids: action.emp_uuids,
        emp_names: action.emp_names,
        emp_mappings: state.emp_mappings,
        emp_urls: action.emp_urls,
        company_id: action.company,
      }
    case 'UPDATE_JOURNEY_INFO':
      return {
        ...state,
        journeyMappings: action.ids,
        tabNames: action.journeys,
      }
    case 'UPDATE_EMAIL_FOLDERS':
      console.log('UPDATE_EMAIL_FOLDERS: ', action)
      return {
        ...state,
        email_folders: action.folders,
      }
    case 'UPDATE_PERSONAL_EMAIL_FOLDERS':
      console.log('UPDATE_PERSONAL_EMAIL_FOLDERS: ', action)
      return {
        ...state,
        personal_email_folders: action.folders,
      }
    case 'LOGIN_UPDATE_ERROR':
      return {
        ...state,
        token: action?.token,
        uuid: action.uuid,
        user_id: action.user_id,
        error: action.error,
        isAuth: action.isAuth,
        fullname: action.fullname,
        role: action.role,
        emp_ids: action.emp_ids,
        emp_uuids: action.emp_uuids,
        emp_names: action.emp_names,
        emp_mappings: state.emp_mappings,
        emp_urls: action.emp_urls,
        company_id: action.company,
      }

    case 'UPDATE_EMP_MAPPINGS':
      return {
        ...state,
        emp_mappings: action.emp_mappings,
        emp_names_mapping: action.emp_names_mapping,
      }

    case 'UPDATE_SIBLINGS_INFO':
      console.log('output4: ', action)
      return {
        ...state,
        sibling_ids: action.sibling_ids,
        sibling_names: action.sibling_names,
        sibling_urls: action.sibling_urls,
      }
    case 'RESET_LOGIN':
      return initialState
    case 'SET_LINKEDIN_ID':
      return {
        ...state,
        linkedin_id: action.linkedin_id,
      }
    default:
      return state
  }
}

export default LoginReducers
