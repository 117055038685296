//import { navigations } from "../../navigations";
import {
  SET_USER_NAVIGATION,
  ADD_EVALUATE_TO_NAVIGATION,
  REMOVE_ELEMENT,
  RESET_NAVIGATION_SIDEBAR,
} from '../actions/NavigationAction'
import navigation from '@src/navigation/vertical'

//const initialState = [...navigations];
const initialState = []

const NavigationReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_USER_NAVIGATION: {
      return [...action.payload]
    }
    case ADD_EVALUATE_TO_NAVIGATION: {
      console.log('ADD_EVALUATE_TO_NAVIGATION')
      var updated_state = [...state]
      let is_jd_already_present = updated_state.filter((k, v) => {
        if (k['title'].includes('Campaign -')) {
          let already_exists = k.children.filter((k1, v1) => {
            return (
              action.campaign_id.toString() ===
              k1.navLink.split('/')[k1.navLink.split('/').length - 1].toString()
            )
          })

          return already_exists.length > 0
        } else {
          return false
        }
      })

      if (is_jd_already_present.length === 0) {
        updated_state.splice(2, 0, action.payload)
      }
      console.log('ADD_EVALUATE_TO_NAVIGATION: updated_state', updated_state)

      return updated_state
    }
    case RESET_NAVIGATION_SIDEBAR:
      console.log('are you inside RESET_NAVIGATION_SIDEBAR')
      return initialState
    case REMOVE_ELEMENT:
      return action.value
    default: {
      return state
    }
  }
}

export default NavigationReducer
