import React from 'react'
import CommentCard from './CommentCard'
import { toast } from 'react-toastify'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
} from '@mui/material'

import { Button } from 'reactstrap'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import withStyles from '@mui/styles/withStyles'
import { styles } from '../../jd/incomplete/Helper'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as commentsApi from '@src/api/CommentsAPI'

function CommentsTabs(props) {
  const [data, setData] = React.useState([])
  const [comments, setComments] = React.useState('')

  const fetchComments = () => {
    commentsApi
      .getComments('', props.id)
      .then((e) => {
        console.log('Comments Data', e.data.Data)
        setData(e.data.Data)
      })
      .catch((e) => {
        toast.error('Not able to fetch data')
      })
  }

  React.useEffect(() => {
    fetchComments()
  }, [props.id])

  const createNewComments = (event, reason) => {
    if (comments.length < 2) {
      toast.error('Please enter a comment with at least 2 characters')
      return
    }
    let token = props.token
    let lead_id = props.id
    let campaign_id = props.campaign_id

    commentsApi
      .postComment(token, lead_id, comments, campaign_id)
      .then((e) => {
        console.log('Comments Data Create', e.data.Data)
        fetchComments()
        setComments('')
      })
      .catch((e) => {
        toast.error('Not able to comments')
      })
  }

  const handleOnChange = (e) => {
    setComments(e.target.value)
  }

  const handleDelete = (id) => {
    commentsApi
      .deleteComment(props.token, id)
      .then((e) => {
        toast.success(e.data.Data)
        fetchComments()
      })
      .catch((e) => {
        toast.error('Failed To delete comment')
      })
  }
  return (
    <>
      <Accordion className="mb-3 border shadow-none">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h5 className="fw-bolder mb-0">Add New Comments</h5>
        </AccordionSummary>
        <AccordionDetails>
          <div className="row">
            <div className="col-12">
              <TextField
                id="outlined-basic"
                label="Write a new comment"
                className="mb-2"
                value={comments}
                onChange={handleOnChange}
                variant="outlined"
                fullWidth
                multiline
                minRows={4}
                maxRows={4}
              />

              <div className="text-end">
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={createNewComments}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      <div className="row">
        {data.map((e) => {
          return (
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <CommentCard
                token={props.token}
                data={{
                  name: e.commented_by,
                  date: e.updated_at,
                  comments: e.comments,
                  id: e.id,
                }}
                lead_id={props.id}
                handleDelete={handleDelete}
              />
            </div>
          )
        })}
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  token: state.LoginReducers.token,
  uuid: state.LoginReducers.uuid,
})

const mapDispatchToProps = (dispatch) => ({})

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(CommentsTabs)),
)
