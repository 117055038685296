// ** React Imports
import { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'

// ** Redux Imports
import { Provider } from 'react-redux'
import { store } from './redux/store'

import ability from './configs/acl/ability'
import { ToastContainer } from 'react-toastify'
import { AbilityContext } from './utility/context/Can'
import { ThemeContext } from './utility/context/ThemeColors'
// ** i18n
import './configs/i18n'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** Fake Database
// import './@fake-db'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/browser'
// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'

// ** Service Worker
import * as serviceWorker from './serviceWorker'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'

import moment from 'moment-timezone'
import './firebase'
import { persistor } from '@src/redux/store'
import history from './history'
import { ReactFlowProvider } from 'reactflow'

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

if (process.env.NODE_ENV !== 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ["localhost", /^https:\/\/devserver\.10xscale\.in\/api/],
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate:
      process.env.REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
    // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.1,
  })
}

// update app version
let installed_version = localStorage.getItem('version') ?? ''
if (installed_version.length === 0) {
  let current_version = process.env.REACT_APP_VERSION ?? ''
  localStorage.setItem('version', current_version)
}

// generate device id
let deviceID = localStorage.getItem('deviceId') ?? ''
if (deviceID.length === 0) {
  const deviceId =
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  localStorage.setItem('deviceId', deviceId)
}

// setup timezone
const zone = Intl.DateTimeFormat().resolvedOptions().timeZone
moment.tz.setDefault(zone)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Suspense fallback={<Spinner />}>
        <AbilityContext.Provider value={ability}>
          <ThemeContext>
            <ReactFlowProvider>
              <LazyApp />
            </ReactFlowProvider>

            <ToastContainer newestOnTop />
          </ThemeContext>
        </AbilityContext.Provider>
      </Suspense>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
)

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('./firebase-messaging-sw.js')
    .then(function (registration) {
      console.log('Registration successful, scope is:', registration.scope)
      console.log('FCM, scope is:', registration.scope)
      return registration.scope
    })
    .catch(function (err) {
      console.log('Service worker registration failed, error:', err)
      console.log('FCM, error:', err)
      return err
    })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// lets make the app work offline
serviceWorker.register()
