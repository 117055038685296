import instance from './API'

export function getAllEmail(
  folder_name,
  start = 0,
  end = 100,
  isdetail = 'n',
  detail_uid = -1,
) {
  console.log('Folder Name ', folder_name)
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      folder: folder_name,
      start,
      end,
      isdetail,
      detail_uid,
    },
  }

  console.log(' 7 years of hell', {
    folder: folder_name,
    start,
    end,
    isdetail,
    detail_uid,
  })
  return instance.get('email/fetch/', config)
}

// export function getAllEmailPersonal(folder_name, next_page_token) {
export function getAllEmailPersonal(folder_name) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      label: folder_name,
      max_results: 10,
      next_page_token: '',
    },
  }

  return instance.get('gmail/threads/', config)
}

export function getCurrentEmailPersonal(id) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      thread_id: id,
    },
  }

  return instance.get('gmail/thread/message/', config)
}

export function getEmailFolderPersonal() {
  console.log('GET EMAIL FOLDER PERSONAL API')

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.get('gmail/labels/', config)
}

//Send Email
export function sendEmailsPersonal(message, subject, email) {
  const formData = new FormData()
  formData.append('message', message)
  formData.append('subject', subject)
  formData.append('to', email)

  console.log('FORM DATA ', formData)
  // console.log("FORM DATA ", message, subject, email);

  return instance.post('gmail/send/', formData, {})
}

//Reply Email
export function replyEmailsPersonal(message, obj) {
  const formData = new FormData()
  formData.append('body', message)
  formData.append('thread_id', obj.thread_id)
  formData.append('recipients', obj.from)
  formData.append('from_email', obj.to)
  formData.append('subject', obj.subject)
  formData.append('in_reply_to', obj.message_id)
  formData.append('references', obj.message_id)
  formData.append('original_message_text', obj.snippet)
  formData.append('original_message_time', obj.date)
  formData.append('original_message_from', obj.from)

  return instance.post('gmail/reply/', formData, {})
}

export function getEmailFolder() {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.get('email/folders/', config)
}

export function deleteEmails(emailIds) {
  const formData = new FormData()
  formData.append('uid', emailIds)

  return instance.post('email/delete/', formData, {})
}

export function sendEmails(message, subject, email) {
  const formData = new FormData()
  formData.append('message', message)
  formData.append('subject', subject)
  formData.append('to', email)

  return instance.post('email/send/', formData, {})
}

export function getEmailSearchResults(query, start, end, folder) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      folder,
      start,
      end,
      query,
    },
  }

  return instance.get('email/search/', config)
}

export function updateEmailLabel(chatID, label) {
  const formData = new FormData()
  formData.append('chatID', chatID)
  formData.append('label', label)
  // Need to be Removed
  return instance.post('chat/email/label/', formData, {})
}

export function updateEmailStarted(chatID) {
  const formData = new FormData()
  formData.append('chatID', chatID)
  return instance.post('chat/email/started/', formData, {})
}

export function getAllRawEmail() {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return instance.get('settings/email/incoming/all/', config)
}
