import instance from './API'

export function getProfileCardData(token, lead_id) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      lead_id,
    },
  }

  return instance.get('lead/profile/card/', config)
}

export function getProfileCardDetails(token, lead_id, campaign_id) {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
    params: {
      lead_id,
      campaign_id,
    },
  }

  return instance.get('lead/profile/details/', config)
}

export function requestLinkedin(token, campaign_id, lead_ids) {
  const formData = new FormData()
  formData.append('campaign_id', campaign_id)
  formData.append('lead_ids', lead_ids)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('lead/linkedin/', formData, config)
}

export function updateCandidateStatus(
  token,
  campaign_id,
  lead_id,
  journey,
  comments,
) {
  const formData = new FormData()
  formData.append('journey_id', journey)
  formData.append('lead_id', lead_id)
  formData.append('campaign_id', campaign_id)
  formData.append('comments', comments)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('lead/status/', formData, config)
}

export function updateBulkCandidateStatus(
  token,
  campaign_id,
  lead_id,
  journey,
  comments,
) {
  const formData = new FormData()
  formData.append('campaign_id', campaign_id)
  formData.append('journey_id', journey)
  formData.append('lead_ids', lead_id)
  formData.append('comments', comments)

  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return instance.post('lead/status/all/', formData, config)
}
