import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import CandidateDetailInfoCard from './infoCard/CandidateDetailsInfoCard'
import CandidateDetailsTabsContainer from './CandidateDetailsTabsContainer'
import withStyles from '@mui/styles/withStyles'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as apis from '@src/api/SettingsApi'
import * as profileApi from '@src/api/CVDetailsApi'
import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { toast } from 'react-toastify'

function CandidateDetailsMainContainer(props) {
  let token = props.token
  let lead_id = props.id
  let campaign_id =
    props.campaign_id?.[props.campaign_id.length - 1] ?? props.campaign_id
  const [userInfo, setUserInfo] = useState([])

  const [data, setData] = useState({
    data: [],
    journey: [],
  })

  const [emailTemplates, setEmailTemplates] = useState([])

  useEffect(async () => {
    apis.getEmailTemplate().then((res) => {
      if (res.data.Response === 'Ok') {
        console.log('getEmailTemplate', res.data.Data)
        setEmailTemplates(res.data.Data)
      }
    })
  }, '')

  useEffect(() => {
    console.log('again called candidate bread clicked', props.history1)
    profileApi
      .getProfileCardData(token, lead_id)
      .then((e) => {
        if (e.status === 200) {
          let journey = e.data.Data.journey
          setUserInfo(e.data?.Data?.data)
          journey.sort(function (a, b) {
            return a.rank.localeCompare(b.rank)
          })
          console.log('DEBUG journey', journey) //list
          setData((prev) => ({
            ...prev,
            data: e.data.Data.data,
            journey: journey,
          }))
        }
      })
      .catch((e) => {
        toast.error(e.message)
      })
  }, [])

  return (
    <div>
      {props?.task_id !== '' ? (
        <div className="justify-content-start breadcrumb-wrapper">
          <Breadcrumb className="ms-1">
            <BreadcrumbItem>
              <Link
                to="/apps/extractcontactdetails"
                onClick={(e) => {
                  console.log('/apps/extractcontactdetails123', props)
                  props.history1.push({
                    pathname: '/apps/extractcontactdetails',
                    task_id: props.task_id,
                    task_name: props.task_name,
                    campaign_name: props.jdName,
                    campaign_id: campaign_id,
                  })
                }}
              >
                Fetch Contact Details
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <span> {props.name} </span>
            </BreadcrumbItem>
          </Breadcrumb>
        </div>
      ) : null}
      <div className="my-10 mx-2">
        <Grid container spacing={2}>
          <Grid item sm={3} xs={12}>
            <CandidateDetailInfoCard
              {...props}
              data={data}
              campaign_id={campaign_id}
              setData={setData}
              emailTemplates={emailTemplates}
              userInfo={userInfo}
            />
          </Grid>
          <Grid item sm={9} xs={12}>
            <CandidateDetailsTabsContainer
              {...props}
              data={data.data}
              campaign_id={campaign_id}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  token: state.LoginReducers.token,
  uuid: state.LoginReducers.uuid,
  user_id: state.LoginReducers.user_id,
  organizerEmail: state.LoginReducers.email,
  organizerName: state.LoginReducers.fullname,
})

const mapDispatchToProps = (dispatch) => ({})

export default withStyles(
  {},
  { withTheme: true },
)(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CandidateDetailsMainContainer),
  ),
)
